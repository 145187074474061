import { PrimeReactProvider } from 'primereact/api';
import Router from './router';
import { ToastContextProvider } from './Lib/Context/toastContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

function App() {

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
      },
      mutations: {
        retry: 1
      },
    }
  });

  return (
    <QueryClientProvider client={queryClient}>
      <PrimeReactProvider>
        <ToastContextProvider>
          <Router />
        </ToastContextProvider>
      </PrimeReactProvider>
    </QueryClientProvider>
  );
}

export default App;
