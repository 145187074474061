import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { PeriodList } from "./periodList";
import { DayList } from "./dayList";
import { InputNumber } from "primereact/inputnumber";
import { FloatLabel } from "primereact/floatlabel";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { useToastContext } from "../../Lib/Context/toastContext";
import { useEffect, useState } from "react";
import { Image } from "primereact/image";


const Types = {
    ADD: "Add",
    EDIT: "Edit",
}

const AddEditExercise = ({ type, exerciseData, setExerciseData, handleSave, videoUploadRef, imageUploadRef, handleDelete = null } : any) => {
    const { showToast } = useToastContext();

    const [errors, setErrors] = useState({
        name: "",
        image: "",
      });

    const resetErrors = () => {
        setErrors({
            name: "",
            image: "",
        });
    }

      const setFieldError = (field: string, errorMessage: string) => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: errorMessage,
        }));
      };

    const onImageSelect = (e:any) => {
        setExerciseData((prev:any) => ({ ...prev, image: e.files[0]?.objectURL }));
    }

    const onVideoSelect = (e:any) => {
        setExerciseData((prev:any) => ({ ...prev, video: e.files[0]?.objectURL }));
    }

    const onPeriodsChange = (e: any) => {
        let _periods = [...(exerciseData?.periods || [])];
    
        if (e.checked) _periods.push(e.value);
        else _periods.splice(_periods.indexOf(e.value), 1);
    
        setExerciseData((prev:any) => ({ ...prev, periods: _periods }));
    };
    
    const onDaysChange = (e: any) => {
        let _days = [...(exerciseData?.days || [])];

        if (e.checked) _days.push(e.value);
        else _days.splice(_days.indexOf(e.value), 1);

        setExerciseData((prev:any) => ({ ...prev, days: _days }));
    };

    const handleChange = (e : any) => {
        const { name, value } = e.target;
        setExerciseData((prev:any) => ({ ...prev, [name]: value }));
    }

    const controlCredentials = () => {
        let valid = true;
        if (!exerciseData?.name) {
            setFieldError("name", "Name is required!");
            valid = false;
        }
        if (!exerciseData?.image) {
            setFieldError("image", "Please upload an image!");
            valid = false;
        }
        return valid;
    }

    const handleSubmit = () => {
        if(!controlCredentials()) {
            showToast({ severity: "error", summary: "Error", detail: "Please fill all the fields!" });
            return;
        }
        handleSave();
    }

    const handleClearUploadedVideo = () => {
        setExerciseData((prev:any) => ({ ...prev, video: "" }));
        videoUploadRef?.current?.clear();
    }

    const handleClearUploadedImage = () => {
        setExerciseData((prev:any) => ({ ...prev, image: "" }));
        imageUploadRef?.current?.clear();
    }

    useEffect(() => {
        resetErrors();
    }, [exerciseData?.id])
    
    const imageUploadTemplate = () => {
        return (
            <>
                {(!exerciseData?.image && errors?.image) ? (
                    <div className="flex flex-column align-items-center">
                        <i className="pi pi-image text-4xl text-red-500"></i>
                        <p className="text-red-500">{errors.image}</p>
                    </div>
                ) : (
                    <>
                        {!exerciseData?.image ? (
                            <div className="flex flex-column align-items-center">
                                <i className="pi pi-image text-4xl text-gray-400"></i>
                                <p>Drag and drop exercise image here</p>
                            </div>
                        ) : (
                            <div className="flex align-items-center gap-3">
                                <Image src={exerciseData?.image} width="150" height="150" />
                                <span>There is already a image! If you want to change it click to <span className="text-orange-500">Choose</span> button.</span>
                                <Button link icon="pi pi-trash" style={{ color: "#E3506B" }} onClick={handleClearUploadedImage} />
                            </div>
                        )}
                    </>
                )}
            </>
        )
    }

    const imageUploadItemTemplate = () => {
        return (
            <div className="flex align-items-center gap-3">
                <Image src={exerciseData?.image} width="150" height="150" />
                <span className="text-left">Image uploaded! If you want to change it click to <span className="text-orange-500">Choose</span> button.</span>
                <Button link icon="pi pi-trash" style={{ color: "#E3506B" }} onClick={handleClearUploadedImage} />
            </div>
        )
    }

    const videoUploadTemplate = () => {
        return (
            <>
                {!exerciseData?.video ? (
                    <div className="flex flex-column align-items-center">
                        <i className="pi pi-video text-4xl text-gray-400"></i>
                        <p>Drag and drop exercise video here</p>
                    </div>
                ) : (
                    <div className="flex align-items-center gap-3">
                        <video width={200} controls>
                            <source src={exerciseData?.video} type="video/mp4" />
                        </video>
                        <span>There ise already a video! If you want to change it click to <span className="text-orange-500">Choose</span> button.</span>
                        <Button link icon="pi pi-trash" style={{ color: "#E3506B" }} onClick={handleClearUploadedVideo} />
                    </div>
                )}
            </>
        )
    }

    const videoUploadItemTemplate = () => {
        return (
            <div className="flex align-items-center gap-3">
                <video width={200} controls>
                    <source src={exerciseData?.video} type="video/mp4" />
                </video>
                <span className="text-left">Video Uploaded! If you want to change it click to <span className="text-orange-500">Choose</span> button.</span>
                <Button link icon="pi pi-trash" style={{ color: "#E3506B" }} onClick={handleClearUploadedVideo} />
            </div>
        )
    }
    
    return (
        <div className="flex flex-column gap-3">
            <div className={`flex ${type === Types.ADD ? "flex-row justify-content-evenly" : "flex-column"}`}>
                <div className={`${type === Types.ADD && "w-30rem"}`}>
                    <div className="flex flex-column gap-2">
                        <label
                            htmlFor="name"
                            className="text-gray-600 font-medium"
                        >
                        Name
                        </label>
                        <InputText
                            id="care-plan-name"
                            value={exerciseData?.name}
                            name="name"
                            onChange={handleChange}
                            className="input-text-appointment w-full"
                            invalid={!!(!exerciseData?.name && errors?.name)}
                        />
                    </div>
                    <Divider />
                    <div className="flex flex-wrap gap-5">
                        {PeriodList?.map((period: any) => (
                            <div className="flex align-items-center" key={period?.name}>
                                <Checkbox
                                    inputId={period?.name}
                                    name={period?.name}
                                    value={period?.value}
                                    onChange={onPeriodsChange}
                                    checked={exerciseData?.periods?.includes(period?.value)}
                                />
                                <label htmlFor={period?.name} className="ml-2">
                                    {period?.value}
                                </label>
                            </div>
                        ))}
                    </div>
                    <Divider />
                    <div className="flex flex-wrap gap-5">
                        {DayList?.map((day: any) => (
                            <div className="flex align-items-center" key={day?.name}>
                                <Checkbox
                                    inputId={day?.name}
                                    name={day?.name}
                                    value={day?.value}
                                    onChange={onDaysChange}
                                    checked={exerciseData?.days?.includes(day?.value)}
                                />
                                <label htmlFor={day?.name} className="ml-2">
                                    {day?.value}
                                </label>
                            </div>
                        ))}
                    </div>
                    <Divider />
                    <div className="mt-5">
                        <div className="flex flex-column flex-wrap gap-3">
                            <div className="flex gap-4 w-full mb-3">
                                <FloatLabel>
                                    <label htmlFor="number-input-set" className="text-gray-600 font-light">
                                        Sets
                                    </label>
                                    <InputNumber
                                        value={exerciseData?.sets}
                                        id="number-input-set"
                                        name="sets"
                                        onValueChange={handleChange}
                                        inputClassName="input-number"
                                    />
                                </FloatLabel>
                                <FloatLabel>
                                    <label htmlFor="number-input-repeat" className="text-gray-600 font-light">
                                        Repeat
                                    </label>
                                    <InputNumber
                                        value={exerciseData?.repeat}
                                        name="repeat"
                                        id="number-input-repeat"
                                        onValueChange={handleChange}
                                        inputClassName="input-number"
                                    />
                                </FloatLabel>
                            </div>
                            <div className="flex gap-4 w-full">
                                <FloatLabel>
                                    <label htmlFor="number-input-tolerance" className="text-gray-600 font-light">
                                        Tolerance (%)
                                    </label>
                                    <InputNumber
                                        value={exerciseData?.tolerance}
                                        name="tolerance"
                                        id="number-input-tolerance"
                                        onValueChange={handleChange}
                                        max={100}
                                        min={0}
                                        inputClassName="input-number"
                                    />
                                </FloatLabel>
                                <FloatLabel>
                                    <label htmlFor="number-input-rest" className="text-gray-600 font-light">
                                        Rest Time (s)
                                    </label>
                                    <InputNumber
                                        value={exerciseData?.restTime}
                                        name="restTime"
                                        id="number-input-rest"
                                        onValueChange={handleChange}
                                        inputClassName="input-number"
                                    />
                                </FloatLabel>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <FloatLabel>
                            <label htmlFor="description" className="text-gray-600 font-light">
                                Description
                            </label>
                            <InputTextarea id="description" className="w-full description-text-area" name="description" value={exerciseData?.description} onChange={handleChange} rows={5} />
                        </FloatLabel>
                    </div>
                </div>
                <Divider layout={`${type === Types.ADD ? "vertical" : "horizontal"}`} />
                <div className="">
                    <div className={`flex flex-column gap-2 ${type === Types.ADD ? "w-30rem" : "w-full"}`}>
                        <FileUpload cancelOptions={{ className: "hidden" }} uploadOptions={{ className: "pointer-events-none opacity-50" }} ref={imageUploadRef} multiple={false} name="exercise-image" accept="image/*" maxFileSize={1000000000} emptyTemplate={imageUploadTemplate} onSelect={onImageSelect} itemTemplate={imageUploadItemTemplate} />
                        <FileUpload cancelOptions={{ className: "hidden" }} uploadOptions={{ className: "pointer-events-none opacity-50" }} ref={videoUploadRef} multiple={false} name="exercise-videos" accept="video/*" maxFileSize={1000000000} emptyTemplate={videoUploadTemplate} itemTemplate={videoUploadItemTemplate} onSelect={onVideoSelect} />
                    </div>
                </div>
            </div>
            <div className="flex justify-content-end align-items-center gap-5 mt-4">
                {type === Types.EDIT && <Button onClick={() => handleDelete(exerciseData)} link label="Delete" icon="pi pi-trash" style={{ color: "#E3506B" }} />}
                <Button label={type === Types.ADD ? "Add" : "Save"} icon="pi pi-save" severity="danger" style={{ backgroundColor: "#E3506B" }} onClick={handleSubmit} />
            </div>
        </div>
    )
}

export default AddEditExercise;