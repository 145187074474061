import { useRef, useState } from "react";
import ExercisesDataView from "./Components/exercises"
import "./contentManagement.css"
import ExerciseRefactor from "./Components/exerciseRefactor";
import AddNewExerciseDialog from "./Components/addNewExerciseDialog";

const ContentManagement = () => {
    const imageUploadRef = useRef<any>(null);
    const videoUploadRef = useRef<any>(null);

    const [exercises, setExercises] = useState<any[]>([
        {
            id: 1,
            name: "Pushups",
            image: "/exercise-image.svg",
            periods: ["Morning"],
            days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
            sets: 12,
            repeat: 3,
            tolerance: 40,
            restTime: 65,
            video: "",
            description: "",
        },
        {
            id: 2,
            name: "Pullups",
            image: "/exercise-image.svg",
            periods: [],
            days: [],
            sets: 0,
            repeat: 0,
            tolerance: 0,
            restTime: 0,
            video: "",
            description: "",
        },
        {
            id: 3,
            name: "Squats",
            image: "/exercise-image.svg",
            periods: [],
            days: [],
            sets: 0,
            repeat: 0,
            tolerance: 0,
            restTime: 0,
            video: "",
            description: "",
        },
        {
            id: 4,
            name: "Lunges",
            image: "/exercise-image.svg",
            periods: [],
            days: [],
            sets: 0,
            repeat: 0,
            tolerance: 0,
            restTime: 0,
            video: "",
            description: "",
        },
        {
            id: 5,
            name: "Plank",
            image: "/exercise-image.svg",
            periods: [],
            days: [],
            sets: 0,
            repeat: 0,
            tolerance: 0,
            restTime: 0,
            video: "",
            description: "",
        },
        {
            id: 6,
            name: "Crunches",
            image: "/exercise-image.svg",
            periods: [],
            days: [],
            sets: 0,
            repeat: 0,
            tolerance: 0,
            restTime: 0,
            video: "",
            description: "",
        },
        {
            id: 7,
            name: "Burpees",
            image: "/exercise-image.svg",
            periods: [],
            days: [],
            sets: 0,
            repeat: 0,
            tolerance: 0,
            restTime: 0,
            video: "",
            description: "",
        },
        {
            id: 8,
            name: "Mountain Climbers",
            image: "/exercise-image.svg",
            periods: [],
            days: [],
            sets: 0,
            repeat: 0,
            tolerance: 0,
            restTime: 0,
            video: "",
            description: "",
        },
        {
            id: 9,
            name: "Jumping Jacks",
            image: "/exercise-image.svg",
            periods: [],
            days: [],
            sets: 0,
            repeat: 0,
            tolerance: 0,
            restTime: 0,
            video: "",
            description: "",
        },
        {
            id: 10,
            name: "Tricep Dips",
            image: "/exercise-image.svg",
            periods: [],
            days: [],
            sets: 0,
            repeat: 0,
            tolerance: 0,
            restTime: 0,
            video: "",
            description: "",
        },
        {
            id: 11,
            name: "Bicep Curls",
            image: "/exercise-image.svg",
            periods: [],
            days: [],
            sets: 0,
            repeat: 0,
            tolerance: 0,
            restTime: 0,
            description: "",
            video: "",
        },
        {
            id: 12,
            name: "Leg Raises",
            image: "/exercise-image.svg",
            periods: [],
            days: [],
            sets: 0,
            repeat: 0,
            tolerance: 0,
            restTime: 0,
            video: "",
            description: "",
        },
        {
            id: 13,
            name: "Russian Twists",
            image: "/exercise-image.svg",
            periods: [],
            days: [],
            sets: 0,
            repeat: 0,
            tolerance: 0,
            restTime: 0,
            video: "",
            description: "",
        },
        {
            id: 14,
            name: "Jump Squats",
            image: "/exercise-image.svg",
            periods: [],
            days: [],
            sets: 0,
            repeat: 0,
            tolerance: 0,
            restTime: 0,
            video: "",
            description: "",
        },
        {
            id: 15,
            name: "High Knees",
            image: "/exercise-image.svg",
            periods: [],
            days: [],
            sets: 0,
            repeat: 0,
            tolerance: 0,
            restTime: 0,
            video: "",
            description: "",
        },
        {
            id: 16,
            name: "Side Plank",
            image: "/exercise-image.svg",
            periods: [],
            days: [],
            sets: 0,
            repeat: 0,
            tolerance: 0,
            restTime: 0,
            video: "",
            description: "",
        },
        {
            id: 17,
            name: "Bicycle Crunches",
            image: "/exercise-image.svg",
            periods: [],
            days: [],
            sets: 0,
            repeat: 0,
            tolerance: 0,
            restTime: 0,
            video: "",
            description: "",
        },
        {
            id: 18,
            name: "Wall Sit",
            image: "/exercise-image.svg",
            periods: [],
            days: [],
            sets: 0,
            repeat: 0,
            tolerance: 0,
            restTime: 0,
            video: "",
            description: "",
        },
        {
            id: 19,
            name: "Calf Raises",
            image: "/exercise-image.svg",
            periods: [],
            days: [],
            sets: 0,
            repeat: 0,
            tolerance: 0,
            restTime: 0,
            video: "",
            description: "",
        },
        {
            id: 20,
            name: "Box Jumps",
            image: "/exercise-image.svg",
            periods: [],
            days: [],
            sets: 0,
            repeat: 0,
            tolerance: 0,
            restTime: 0,
            video: "",
            description: "",
        },
    ]);
    const [addNewExerciseDialogVisible, setAddNewExerciseDialogVisible] = useState<boolean>(false);
    const [selectedExercise, setSelectedExercise] = useState<any>();

    return (
        <>
            <div className="grid gap-3 justify-content-between">
                <div className="col">
                    <ExercisesDataView setAddNewExerciseDialogVisible={setAddNewExerciseDialogVisible} selectedExercise={selectedExercise} setSelectedExercise={setSelectedExercise} exercises={exercises} />
                </div>
                <div className="col">
                    <ExerciseRefactor videoUploadRef={videoUploadRef} imageUploadRef={imageUploadRef} exercises={exercises} setExercises={setExercises} selectedExercise={selectedExercise} setSelectedExercise={setSelectedExercise} />
                </div>
            </div>
            <AddNewExerciseDialog videoUploadRef={videoUploadRef} imageUploadRef={imageUploadRef} addNewExerciseDialogVisible={addNewExerciseDialogVisible} setAddNewExerciseDialogVisible={setAddNewExerciseDialogVisible} exercises={exercises} setExercises={setExercises} />
        </>
    )
}

export default ContentManagement