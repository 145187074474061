import FirstGridForCardsReport from "./firstGridForCardsReport"
import SecondGridForCardsReport from "./secnodGridForCardsReport"
import ThirdGridForCardsReport from "./thirdGridForCardsReport"
import './reportAnalytics.css';

const ReportAnalytics = () => {
    return (
        <div className="grid nested-grid">
            <div className="col-12 xl:col-8">
                <FirstGridForCardsReport />
            </div>
            <div className="col" >
                <SecondGridForCardsReport />
            </div>
            <div className="col-12">
                <ThirdGridForCardsReport />
            </div>
        </div>

    )
}

export default ReportAnalytics