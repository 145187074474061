import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { useToastContext } from "../../Lib/Context/toastContext";
import AddEditExercise from "./addEditExercise";

const AddNewExerciseDialog = ({ videoUploadRef, imageUploadRef, addNewExerciseDialogVisible, setAddNewExerciseDialogVisible, exercises, setExercises } : any) => {
    const { showToast } = useToastContext();

    const [exercise, setExercise] = useState({
        id: 0,
        name: "",
        periods: [],
        days: [],
        sets: 0,
        repeat: 0,
        tolerance: 0,
        restTime: 0,
        image: "",
        video: "",
        description: "",
    })

    const resetForm = () => {
        setExercise({
            id: 0,
            name: "",
            periods: [],
            days: [],
            sets: 0,
            repeat: 0,
            tolerance: 0,
            restTime: 0,
            image: "",
            video: "",
            description: "",
        })
        imageUploadRef?.current?.clear();
        videoUploadRef?.current?.clear();
    }

    const handleAdd = () => {
        setExercise({ ...exercise, id: exercises.length + 1 });
        setExercises([...exercises, exercise]);
        showToast({ severity: "success", summary: "Success", detail: "Exercise added successfully" });
        hideDialog();
    }
    
    const hideDialog = () => {
        setAddNewExerciseDialogVisible(false);
        resetForm();
    }

    return (
        <Dialog
            header="Add New Exercise"
            visible={addNewExerciseDialogVisible}
            style={{ width: "80rem" }}
            onHide={hideDialog}
        >
            <AddEditExercise
                type="Add"
                exerciseData={exercise}
                setExerciseData={setExercise}
                handleSave={handleAdd}
                imageUploadRef={imageUploadRef}
                videoUploadRef={videoUploadRef}
            />
        </Dialog>
    )
}

export default AddNewExerciseDialog;