import { Card } from "primereact/card"
import GeneralAdherenceLineChart from "../Charts/generalAdherenceLineChart"
import SuccessBarChart from "../Charts/successBarChart"

const ThirdGridForCardsReport = () => {
    return (
        <div className="grid">
           <div className="col">
                <Card className="h-full">
                    <GeneralAdherenceLineChart contentManagmentInput={true}/>
                </Card>
            </div>
            <div className="col">
                <Card className="h-full">
                    <SuccessBarChart contentManagmentInput={true}/>
                </Card>
            </div>
        </div>
    )
}

export default ThirdGridForCardsReport