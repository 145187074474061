import { DataView } from 'primereact/dataview';
import { Card } from "primereact/card";
import ClinickListTinyDonutChart from "../../Charts/clinickListTinyDonutChart";

const ClinickList = ({ clinicks, selectedClinicIndex, setSelectedClinicIndex }: any) => {
    const dateBodyTemplate = (addDate: Date) => {
        if (addDate) {
            const formattedDate = addDate?.toLocaleDateString("tr-TR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            });
            return formattedDate.replace(", ", " ")
        };
    }

    const itemTemplate = (clinic: any, index: any) => {
        return (
            <div className='col-12 px-2 cardForClinicList clickableDiv' onClick={() => setSelectedClinicIndex(index)}>
                <Card className="p-0" style={selectedClinicIndex === index ? { backgroundColor: "#111F52", color: "white" } : undefined}>
                    <div className="flex flex-column gap-2">
                        <div className="flex flex-row justify-content-end">
                            <span>{dateBodyTemplate(new Date("2023-08-01"))}</span>
                        </div>
                        <span className="text-xl font-bold">{clinic.name}</span>
                        <span>{clinic.city}/{clinic.country}</span>

                        <div className="flex flex-row justify-content-between">
                            <div className="flex flex-column justify-content-center gap-2">
                                <span className="text-xl font-bold">{77}</span>
                                <span>Patients</span>
                            </div>
                            <div className="flex flex-column justify-content-center gap-2">
                                <span className="text-xl font-bold">{clinic.consultantsCount}</span>
                                <span>Specialist</span>
                            </div>
                            <div className="mb-4">
                                <ClinickListTinyDonutChart selectedClinicIndex={selectedClinicIndex} index={index}/>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        );
    };

    const listTemplate = (clinics: any): any => {
        if (!clinics || clinics.length === 0) return null;

        let clinic = clinics.map((c: any, index: any) => {
            return itemTemplate(c, index);
        });

        return <div className="grid grid-nogutter gap-2" style={{ backgroundColor: '#FFFFFF' }}>{clinic}</div>;
    };

    return (
        <div className=' mt-2' style={{ overflowY: "scroll", height: "70rem" }}>
            <DataView value={clinicks} listTemplate={listTemplate} />
        </div>

    )
}

export default ClinickList