import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useToastContext } from "../Lib/Context/toastContext";
import { Image } from "primereact/image";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { FloatLabel } from "primereact/floatlabel";
import { useEffect, useState } from "react";
import { useLoginMutation } from "../Hooks/useGetDataQuery";

const Login = () => {
    const { showToast } = useToastContext();

    const navigate = useNavigate();

    const {
        mutate: loginMutation,
        isError: isErrorLogin,
        isSuccess: isSuccessLogin,
    } = useLoginMutation();

    const [email, setEmail] = useState<any>('')
    const [password, setPassword] = useState<any>('')

    const [emailIsNotGiven, setEmailIsNotGiven] = useState<any>('')
    const [passwordIsNotGiven, setPasswordIsNotGiven] = useState<any>('')


    useEffect(() => {
        if(isSuccessLogin){
            showToast({ severity: 'success', summary: 'Success', detail: 'Login Successful!' });
            navigate({ pathname: "/mobitheraAdmin" })
        }
    }, [isSuccessLogin]);

    useEffect(() => {
        if(isErrorLogin){
            showToast({ severity: 'error', summary: 'Error', detail: 'No such user, please check you credentials' });
        }
    }, [isErrorLogin]);


    const loginToMainLayout = () => {

        let escape = false
        if (!email) {
            escape = true;
            setEmailIsNotGiven("Please enter email or username")
        }
        if (!password) {
            escape = true;
            setPasswordIsNotGiven("Please enter password")
        }

        if (escape) {
            return
        }
        const credentials = {
            username: email,
            password: password
        }

        loginMutation(credentials)
    }

    const dontHaveAccount = () => <span className='font-medium text-color-secondary'>Don't have an account? <a href='#' className='text-black-alpha-90'>Sign up</a></span>

    return (
        <div style={{ backgroundColor: "#FFF2F2" }} className='w-full h-full '>
            <div className='flex flex-row'>
                <div className='w-8 overflow-hidden' style={{ height: "100vh" }}>
                    <Image src="/signin-image.png" alt='Signing Image' width='100%' height='100%' />
                </div>
                <div className='w-full p-7 overflow-hidden mr-2' style={{ height: "100vh" }}>
                    <div className='flex justify-content-between mb-6'>
                        <div className='flex flex-column'>
                            <Image src="/Logo.png" />
                            <span className='text-3xl font-medium mt-2'>Sign in</span>
                        </div>
                        {dontHaveAccount()}
                    </div>
                    <div className='flex flex-column gap-3 align-items-center mb-4'>
                        <Button onClick={() => console.log("")} className='w-full flex justify-content-center' style={{ backgroundColor: '#fff' }} size="large" severity='secondary' rounded outlined>
                            <div className='flex gap-2 align-items-center'>
                                <img alt="google-logo" src="/social-media-google.png"></img>
                                <span className='font-medium text-black-alpha-70'>Continue with Google</span>
                            </div>
                        </Button>
                        <Button className='w-full flex justify-content-center' style={{ backgroundColor: '#fff' }} size="large" severity='secondary' rounded outlined>
                            <div className='flex gap-2 align-items-center'>
                                <img alt="google-logo" src="/social-media-apple.png"></img>
                                <span className='font-medium text-black-alpha-70'>Continue with Apple</span>
                            </div>
                        </Button>
                    </div>
                    <div className='flex justify-content-center align-items-center mb-4'>
                        <Divider />
                        <span className='p-3 text-color-secondary font-semibold'>OR</span>
                        <Divider />
                    </div>
                    <div className='p-fluid'>
                        <div className='flex flex-column gap-5 align-items-center mb-2'>
                            <div className='w-full'>
                                <FloatLabel>
                                    <InputText invalid={!email && emailIsNotGiven} className='p-inputtext-lg w-full' variant="filled" id='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                                    <label htmlFor='email'>User name or email address</label>
                                </FloatLabel>
                                {(!email && emailIsNotGiven) && <small className='text-red-500'>{emailIsNotGiven}</small>}
                            </div>
                            <div className='w-full'>
                                <FloatLabel>
                                    <Password invalid={!password && passwordIsNotGiven} id="password" className='p-inputtext-lg w-full' toggleMask variant='filled' value={password} onChange={(e) => setPassword(e.target.value)} />
                                    <label htmlFor="password">Password</label>
                                </FloatLabel>
                                {(!password && passwordIsNotGiven) && <small className='text-red-500'>{passwordIsNotGiven}</small>}
                            </div>
                        </div>
                        <div className='flex justify-content-end align-content-end mb-2'>
                            <a href='#' className='text-black-alpha-90'>Forgot your password</a>
                        </div>
                        <Button className='w-7 sm:w-5 md:w-5 lg:w-5 xl:w-5' size="large" label='Sign in' severity='secondary' rounded onClick={loginToMainLayout} />
                    </div>
                    <div className='mt-4'>
                        {dontHaveAccount()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login