import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { InputTextarea } from "primereact/inputtextarea";
import { useEffect, useState } from "react";

const Messages = ({ conversations, setConversations, selectedConversation } : any) => {
    const userId : string = "456";

    const [message, setMessage] = useState<string>();

    const timestamp = (date: any) => {
        const datestamp = new Date(date);
        const hours = datestamp.getHours();
        const minutes = datestamp.getMinutes().toString().padStart(2, '0');
        return hours + ":" + minutes;
    };

    const sendMessage = () => {
        if(message?.trim() === "" || !message) return;

        const newMessage = {
            messageId: "123",
            senderId: userId,
            text: message,
            timestamp: new Date().toISOString(),
            status: 'delivered'
        }

        setConversations((prevConversations:any) =>
            prevConversations.map((conversation:any) =>
              conversation.id === conversations[selectedConversation]?.id
                ? {
                    ...conversation,
                    messages: [...conversation.messages, newMessage],
                    lastMessage: newMessage
                  }
                : conversation
            )
          );

        setMessage("");
    }

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
        }
    };

    useEffect(() => {
        setMessage("");
    }, [selectedConversation])

    const header = (
        <>
            <div className="flex align-items-center justify-content-between">
                <div className="flex flex-column gap-3">
                    <span className="text-2xl font-medium">{conversations[selectedConversation]?.participants[0]?.username}</span>
                    <span className="font-light text-600 text-md">{conversations[selectedConversation]?.participants[0]?.description}</span>
                </div>
                {/*<div className="flex align-items-center gap-1">
                    <Button link icon="pi pi-ellipsis-v" className="text-black-alpha-90" />
                    <Button rounded label="Mark as done" severity="secondary" className="bg-blue-900" />
                </div>*/}
            </div>
            <Divider />
        </>
    )

    return (
        <div className="flex flex-column border-2 border-200 border-round-xl p-5 h-full">
            {header}
            <div className="flex-grow-1 overflow-y-auto">
                {conversations[selectedConversation]?.messages?.map((message : any) => (
                    <div className={`flex mt-1 ${message?.senderId === userId ? "justify-content-end" : "justify-content-start"}`}>
                        <div className="flex flex-column justify-content-start text-justify gap-1" style={{ maxWidth: '70%' }}>
                            <div className={`border-1 border-round-xl p-3 ${message?.senderId === userId ? "bg-pink-50 border-pink-100" : "border-200"}`}>
                                {message?.text}
                            </div>
                            <small className="text-600 text-md">{timestamp(message?.timestamp)}</small>
                        </div>
                    </div>
                ))}
            </div>
            <div className="border-1 border-round-xl border-200 p-2 mt-5">
                <div className="flex flex-column align-items-end gap-3">
                    <InputTextarea value={message} onKeyDown={handleKeyDown} onChange={(e:any) => setMessage(e.target.value)} autoResize rows={1} className="w-full border-none shadow-none" placeholder="Write a response..." />
                    <Button onClick={sendMessage} label="Send" severity="secondary" icon="pi pi-send" className="bg-white text-black-alpha-90 border-200" rounded />
                </div>
            </div>
        </div>
    )
}

export default Messages;