import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register necessary chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const GenderBarChart = () => {
  
  const data = {
    labels: ["Apr 21", "May 21", "Jun 21", "Jul 21"],
    datasets: [
      {
        label: "Male",
        data: [30, 50, 68, 90],  
        backgroundColor: "#4E5B87", 
        borderRadius: 4, 
        barPercentage: 0.6,
      },
      {
        label: "Female",
        data: [25, 45, 120, 80],  
        backgroundColor: "#E06686", 
        borderRadius: 4,
        barPercentage: 0.6,
      },
    ],
  };

 
  const options:any = {
    responsive: true,
    maintainAspectRatio: true, 
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          usePointStyle: true, 
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem:any) {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}k`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: false,
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value:any) {
            return `${value}k`;
          },
        },
        grid: {
          drawBorder: false, 
        },
      },
    },
  };

  return (
    <div className='flex justify-content-center w-full h-12rem'>
      <Bar data={data} options={options} />
    </div>
  );
};

export default GenderBarChart;