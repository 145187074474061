import { useEffect, useState } from "react";
import { useToastContext } from "../../Lib/Context/toastContext";
import { Button } from "primereact/button";
import { BlockUI } from "primereact/blockui";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { SelectButton } from "primereact/selectbutton";
import { countries } from "../../Utils/countryList";
import { useAddPatientMutation } from "../../Hooks/useGetDataQuery";

const AddPatientDialog = ({ setPatientList, addPatientDialogVisible, setAddPatientDialogVisible }: any) => {
    const { showToast } = useToastContext();
    const {
        mutate: addPatientMutation,
        isSuccess: isSuccessPatientMutation,
        isError: isErrorPatientMutation,
    } = useAddPatientMutation();

    const [patientName, setPatientName] = useState<any>()
    const [patientSurname, setPatientSurname] = useState<any>()
    const [nationality, setNationality] = useState<any>(undefined)
    const [nationalIdentityNumber, setNationalIdentityNumber] = useState<any>()
    const [blockUI, setBlockUI] = useState(false);

    const genderOptions = ['Male', 'Female'];
    const [gender, setGender] = useState(genderOptions[0]);

    const [codeForPhoneNumber, setCodeForPhoneNumber] = useState<any>({ name: 'Turkey', code: 'TR', phoneCode: '+90' })
    const [phoneNumber, setPhoneNumber] = useState<any>()

    const [nameIsNotGiven, setNameIsNotGiven] = useState<any>(false)
    const [surnameIsNotGiven, setSurnameIsNotGiven] = useState<any>(false)
    const [nationalityIsNotGiven, setNationalityIsNotGiven] = useState<any>()
    const [nationalIdentityNumberIsNotGiven, setNationalIdentityNumberIsNotGiven] = useState<any>(false)


    useEffect(() => {
        if (isSuccessPatientMutation) {
            const newPatien = {
                firstName: patientName,
                lastName: patientSurname,
                dateCreated: new Date(),
                nationality: nationality.code,
                nationalIdentityNumber: nationalIdentityNumber,
                phoneNumber: phoneNumber ? codeForPhoneNumber.phoneCode + "" + phoneNumber : undefined,
                gender: gender,
                // status: "Disactive",
                // age:77,
                // hospitalName: "City Hospital"
            }

            setPatientList((prevList: any) => prevList ? [...prevList, newPatien] : [newPatien])
            showToast({ severity: 'success', summary: 'Success', detail: 'Patient added successfully' });
            onaddPatientDialogHide()
            setBlockUI(false)
        }

    }, [isSuccessPatientMutation]);

    useEffect(() => {
        if(isErrorPatientMutation){
            showToast({ severity: 'error', summary: 'error', detail: 'Patient could not be added' });
            onaddPatientDialogHide()
            setBlockUI(false)
        }
    }, [isErrorPatientMutation]);

    const onaddPatientDialogHide = () => {
        addPatientDefault()
        setAddPatientDialogVisible(false)
    }

    const onSavePatientDialog = () => {
        let escape = false
        if (!patientName) {
            escape = true;
            setNameIsNotGiven(true)
        }
        if (!patientSurname) {
            escape = true;
            setSurnameIsNotGiven(true)
        }
        if (!nationality) {
            escape = true;
            setNationalityIsNotGiven(true)
        }
        if (!nationalIdentityNumber) {
            escape = true;
            setNationalIdentityNumberIsNotGiven(true)
        }

        if (escape) {
            return
        }

        const newPatien = {
            firstName: patientName,
            lastName: patientSurname,
            dateCreated: new Date(),
            nationality: nationality.code,
            nationalIdentityNumber: nationalIdentityNumber,
            phoneNumber: phoneNumber ? codeForPhoneNumber.phoneCode + "" + phoneNumber : undefined,
            gender: gender,
            // status: "Disactive",
            // age:77,
            // hospitalName: "City Hospital"
        }

        setBlockUI(true)
        addPatientMutation(newPatien)
    }

    const addPatientDefault = () => {
        setPatientName(undefined);
        setPatientSurname(undefined);
        setNationality(undefined)
        setNationalIdentityNumber(undefined);
        setPhoneNumber(undefined);
        setCodeForPhoneNumber({ name: 'Turkey', code: 'TR', phoneCode: '+90' });
        setGender(genderOptions[0])

        setNameIsNotGiven(false);
        setSurnameIsNotGiven(false);
        setNationalityIsNotGiven(false)
        setNationalIdentityNumberIsNotGiven(false)
    }

    const selectedCountryTemplate = (option: any, props: any) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img alt={option.name} src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const selectedCountryTemplateForPhone = (option: any, props: any) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img alt={option.name}
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option: any) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`} className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                <div>{option.name}</div>
            </div>
        );
    };

    const countryOptionTemplateForPhone = (option: any) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`} className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                <div>{option.name}</div>
                <div className='ml-2'>{option.phoneCode}</div>
            </div>
        );
    };

    const footerContent = (
        <div className="flex justify-content-center  flex-wrap">
            <Button label="Save" rounded style={{ backgroundColor: "#111F52", width: "20%" }} onClick={onSavePatientDialog} />
        </div>
    );

    return (
        <BlockUI
            blocked={blockUI}
            fullScreen
            style={{ backgroundColor: "transparent" }}
            template={
                <i className="pi pi-spin pi-spinner" style={{ fontSize: "3rem" }}></i>
            }
        >
            <Dialog footer={footerContent} header="New Patient" visible={addPatientDialogVisible} style={{ width: '40vw' }} onHide={onaddPatientDialogHide}>
                <div className="flex flex-column flex-wrap gap-4 justify-content-center m-6">
                    <div className="flex flex-column gap-2 align-items-center userManagmentButton" >
                        <SelectButton value={gender} onChange={(e) => setGender(e.value)} options={genderOptions} />
                    </div>

                    <div className="flex flex-column gap-2  userManagmentInput">
                        <label htmlFor="name"><i className="pi pi-asterisk p-overlay-badge" style={{ fontSize: '0.5rem', color: 'red' }}>
                        </i> Patient Name</label>
                        <InputText id="name" value={patientName}
                            className={nameIsNotGiven ? patientName ? undefined : "p-invalid mr-2" : undefined}
                            onChange={e => setPatientName(e.target.value)} />
                        {!patientName && nameIsNotGiven && <Message severity="error" text="Name is required" />}
                    </div>

                    <div className="flex flex-column gap-2 userManagmentInput" >
                        <label htmlFor="surname"><i className="pi pi-asterisk p-overlay-badge" style={{ fontSize: '0.5rem', color: 'red' }}>
                        </i> Patient Surname</label>
                        <InputText id="surname" value={patientSurname}
                            className={surnameIsNotGiven ? patientSurname ? undefined : "p-invalid mr-2" : undefined}
                            onChange={e => setPatientSurname(e.target.value)} />
                        {!patientSurname && surnameIsNotGiven && <Message severity="error" text="Surname is required" />}
                    </div>

                    <div className="flex flex-column gap-2 userManagmentDropdown" >
                        <label htmlFor="nationality"><i className="pi pi-asterisk p-overlay-badge" style={{ fontSize: '0.5rem', color: 'red' }}>
                        </i> Nationality</label>
                        <Dropdown value={nationality} onChange={(e) => setNationality(e.value)} options={countries} optionLabel="name"
                            valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} showClear
                            className={nationalityIsNotGiven ? nationality ? 'w-full' : "p-invalid mr-2 w-full" : 'w-full'} style={{ height: "2.8rem" }} />
                        {!nationality && nationalityIsNotGiven && <Message severity="error" text="Nationality is required" />}
                    </div>

                    <div className="flex flex-column gap-2 userManagmentInput" >
                        <label htmlFor="nationalIdentityNumber"><i className="pi pi-asterisk p-overlay-badge" style={{ fontSize: '0.5rem', color: 'red' }}>
                        </i> National Identity Number</label>
                        <InputText id="nationalIdentityNumber" value={nationalIdentityNumber}
                            className={nationalIdentityNumberIsNotGiven ? nationalIdentityNumber ? undefined : "p-invalid mr-2" : undefined}
                            onChange={e => setNationalIdentityNumber(e.target.value)} />
                        {!nationalIdentityNumber && nationalIdentityNumberIsNotGiven && <Message severity="error" text="National Identity Number is required" />}
                    </div>

                    <div className="flex flex-column gap-2">
                        <label htmlFor="phone">Phone Number</label>
                        <div className="combo-box flex align-content-center align-items-center ">
                            <Dropdown style={{ height: '2.8rem', width: '5rem' }} value={codeForPhoneNumber} options={countries} optionLabel="name"
                                valueTemplate={selectedCountryTemplateForPhone} itemTemplate={countryOptionTemplateForPhone}
                                onChange={e => setCodeForPhoneNumber(e.target.value)} />
                            <InputNumber className='w-full' value={phoneNumber} onChange={(e) => setPhoneNumber(e.value)} useGrouping={false} />
                        </div>
                    </div>
                </div>
            </Dialog>
        </BlockUI>
    )
}

export default AddPatientDialog