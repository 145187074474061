import { Card } from "primereact/card"
import ClinicksDonutChart from "../Charts/clinicksDonutChart"
import { Badge } from "primereact/badge"
import { Button } from "primereact/button"
import { useNavigate } from "react-router-dom"

const SecondGridForCardsReport = () => {
    const navigate = useNavigate();

    const navigateToAllClinicks = () => {
        navigate({ pathname: "/mobitheraAdmin/reportAnalytics/allClinics" })
    }

    const footer = (
        <div className="flex justify-content-end -mt-1">
            <Button size="small" label="See all clinics" severity="secondary" text icon="pi pi-arrow-right" iconPos="right" onClick={navigateToAllClinicks} />
        </div>
    );

    return (
        <Card className="h-full" title="Top Clinics" footer={footer}>
            <ClinicksDonutChart />

            <div className="flex flex-row justify-content-between mt-5">
                <div className="flex flex-column gap-2">
                    <div className="flex flex-row  gap-3">
                        <Badge className="mt-2" style={{ backgroundColor: "#111F52" }}></Badge>
                        <span>Grand Medical</span>
                        <span style={{ color: "#00B69B" }}>
                            <i className="pi pi-angle-double-up"></i> 43.29%
                        </span>
                    </div>
                    <div className="flex flex-row gap-3">
                        <Badge className="mt-2" style={{ backgroundColor: "#22C55E" }}></Badge>
                        <span>Medipol</span>
                        <span style={{ color: "#00B69B" }}>
                            <i className="pi pi-angle-double-up"></i> 36.11%
                        </span>
                    </div>
                    <div className="flex flex-row gap-3">
                        <Badge className="mt-2" style={{ backgroundColor: "#EF4444" }}></Badge>
                        <span>Medicana</span>
                        <span style={{ color: "#00B69B" }}>
                            <i className="pi pi-angle-double-up"></i> 40%
                        </span>
                    </div>
                    <div className="flex flex-row gap-3">
                        <Badge className="mt-2" style={{ backgroundColor: "#E3506B" }}></Badge>
                        <span>Woldh</span>
                        <span style={{ color: "#00B69B" }}>
                            <i className="pi pi-angle-double-up"></i> 25.53%
                        </span>
                    </div>
                    <div className="flex flex-row gap-3">
                        <Badge className="mt-2" style={{ backgroundColor: "#EAB308" }}></Badge>
                        <span>Others</span>
                        <span style={{ color: "#B60000" }}>
                            <i className="pi pi-angle-double-down"></i> 12.14%
                        </span>
                    </div>
                </div>

                <div className="flex flex-column gap-2">
                    <span>105</span>
                    <span>95</span>
                    <span>84</span>
                    <span>66</span>
                    <span>152</span>
                </div>

            </div>
        </Card>
    )
}

export default SecondGridForCardsReport