import { Toast } from "primereact/toast";
import { createContext, useContext, useRef } from "react";

interface ToastOptions {
    severity: 'success' | 'info' | 'warn' | 'error';
    summary: string | undefined;
    detail: string;
}

interface ToastContextIntf {
    showToast: (options: ToastOptions) => void;
}

const ToastContext = createContext<ToastContextIntf | undefined>(undefined);

export const ToastContextProvider = ({ children } : any) => {
    const toastRef = useRef<Toast>(null);

    const showToast = (options: ToastOptions) => {
        if(!toastRef?.current) return;
        toastRef.current?.show({ ...options });
    }

    return (
        <ToastContext.Provider value={{ showToast }}>
            <Toast ref={toastRef} />
            {children}
        </ToastContext.Provider>
    )
}

export const useToastContext = () => {
    const context = useContext(ToastContext);
    if(!context) {
        throw new Error('useToastContext must be used within a ToastContextProvider');
    }
    return context;
}