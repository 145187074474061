import { FilterMatchMode } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { useEffect, useState } from "react";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import AddPatientDialog from "./addPatientDialog";
import { useGetPatienListQuery } from "../../Hooks/useGetDataQuery";
import { BlockUI } from "primereact/blockui";

const PatientList = ({ lazyParamsForPatients, patientList, setPatientList, showAddButton }: any) => {
    const columns = [
        {
            field: "firstName",

        },
        {
            field: "lastName",

        },
        {
            field: "medicalRecordNumber",

        },
        {
            field: "nationalIdentityNumber",

        },
        {
            field: "age",

        },
        {
            field: "dateCreated",

        },
        {
            field: "action"
        }
    ];

    const { isSuccess, isError, data } = useGetPatienListQuery(showAddButton);

    useEffect(() => {
        if (isSuccess && showAddButton) {
            setPatientList(data.data)
            setBlockUI(false)
        }

    }, [isSuccess]);

    useEffect(() => {
        if (isError && showAddButton) {
            setBlockUI(false)
        }

    }, [isError]);

    const [blockUI, setBlockUI] = useState(showAddButton);

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        firstName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        lastName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        medicalRecordNumber: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        nationalIdentityNumber: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        age: { value: null, matchMode: FilterMatchMode.EQUALS },
        dateCreated: { value: null, matchMode: FilterMatchMode.DATE_IS },
        status: { value: null, matchMode: FilterMatchMode.STARTS_WITH }
    });

    const [addPatientDialogVisible, setAddPatientDialogVisible] = useState(false);

    const dateBodyTemplate = (rowData: any) => {
        const date = new Date(rowData.dateCreated)
        if (date) {
            const formattedDate = date.toLocaleDateString("tr-TR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            });
            return formattedDate.replace(", ", " ")
        };
    }

    const dateFilterTemplate = (options: any) => {
        return <Calendar value={options.value} onChange={(e) => options.filterApplyCallback(e.value, options.index)} />;
    };

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const header = () => {
        return (
            <div className="flex justify-content-end gap-3">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search by first or last name" />
                </IconField>

                {showAddButton && <Button label="Add New" icon="pi pi-plus" rounded severity="danger" style={{ backgroundColor: "#E3506B" }}
                    onClick={() => setAddPatientDialogVisible(true)} />}
            </div>
        );
    };

    const actionButtons = (rowData: any) => {
        return (
            <div className="flex flex-row flex-nowrap ">
                <Button className="m-1" rounded text icon="pi pi-pen-to-square" severity="secondary" size="small" />
                <Button className="m-1" rounded text icon="pi pi-trash" severity="secondary" size="small" />
            </div>
        )
    }

    const dynamicColumns = columns.map((col: any) => {
        if (col.field === "firstName") {
            return (
                <Column
                    key="firstName"
                    field={col.field}
                    header="First Name"
                    dataType='text'
                    sortable
                    filter
                />
            );
        }
        else if (col.field === "lastName") {
            return (
                <Column
                    key="lastName"
                    field={col.field}
                    header="Last Name"
                    dataType='text'
                    sortable
                    filter
                />
            );
        }
        else if (col.field === "medicalRecordNumber") {
            return (
                <Column
                    key="medicalRecordNumber"
                    field={col.field}
                    header="Medical Record Number"
                    dataType='text'
                    sortable
                    filter
                />
            );
        }
        else if (col.field === "nationalIdentityNumber") {
            return (
                <Column
                    key="nationalIdentityNumber"
                    field={col.field}
                    header="Identity Number"
                    dataType='text'
                    sortable
                    filter
                />
            );
        } else if (col.field === "age") {
            return (
                <Column
                    key="age"
                    field={col.field}
                    header="Age"
                    dataType='numeric'
                    sortable
                    filter

                />
            );
        } else if (col.field === "dateCreated") {
            return (
                <Column
                    key="dateCreated"
                    field={col.field}
                    header="Create Date"
                    dataType='date'
                    sortable
                    body={dateBodyTemplate}
                    filter
                    filterElement={dateFilterTemplate}
                    showApplyButton={false}
                />
            );
        } else {
            return (
                <Column
                    key="action"
                    field={col.field}
                    header="Actions"
                    body={actionButtons}
                />
            );
        }
    });

    return (
        <div className="m-0">
            <BlockUI
                blocked={blockUI}
                fullScreen
                style={{ backgroundColor: 'transparent' }}
                template={<i className='pi pi-spin pi-spinner' style={{ fontSize: '3rem' }}></i>}
            >
                <DataTable paginator rows={lazyParamsForPatients.rows} value={patientList} filters={filters} tableStyle={{ minWidth: '50rem' }} header={header} globalFilterFields={['firstName', 'lastName']} >
                    {dynamicColumns}
                </DataTable>

                <AddPatientDialog setPatientList={setPatientList} addPatientDialogVisible={addPatientDialogVisible} setAddPatientDialogVisible={setAddPatientDialogVisible} />
            </BlockUI>
        </div>


    )
}

export default PatientList