import axios, { AxiosInstance } from "axios";

const baseURL: string = "https://mobithera.tiga.health/api-dev";
export const api: AxiosInstance = axios.create({
    baseURL: baseURL,
})

export class Axios {
    public static setAuthToken(accessToken: any, refreshToken: any) {
        api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
        api.defaults.headers.common["RefreshToken"] = refreshToken;
    }

    public static getAuthToken() {
        const accessToken = localStorage.getItem("accessToken");
        const refreshToken = localStorage.getItem("refreshToken");
        return { accessToken, refreshToken };
    }
}