import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const ClinicksDonutChart = () => {
    const data = {
        labels: ['Grand Medical', 'Medipol', 'Medicana', 'WoldH', 'Others'],
        datasets: [
            {
                label: 'Clinics',
                data: [102, 95, 84, 66, 152],
                backgroundColor: [
                    '#111F52',
                    '#22C55E',
                    '#EF4444',
                    '#E3506B',
                    '#EAB308'
                ],
                hoverOffset: 4,
            },
        ],
    };

    const options: any = {
        responsive: true,
        cutout: '60%',
        plugins: {
            legend: {
                display: false
            },
        },
    };

    return (
        <div className='flex justify-content-center w-full h-18rem'>
            <Doughnut data={data} options={options} />
        </div>
    )

};

export default ClinicksDonutChart;