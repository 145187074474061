import { TabPanel, TabView } from "primereact/tabview"
import { useOutletContext } from "react-router-dom";
import PatientList from "./Patient/patientList";
import './userManagement.css';
import SpecialistList from "./Specialist/specialistList";
import { useEffect, useState } from "react";
import AdminList from "./Admin/adminList";


const UserManagement = () => {
    const { lazyParams }: any = useOutletContext();

    const [patientList, setPatientList] = useState<any>();
    const [specialistList, setSpecialistList] = useState<any>();
    const [adminList, setAdminList] = useState<any>();

    return (
        <div className="userManagmentTable">
            <TabView >
                <TabPanel header="Patients" leftIcon="pi pi-user mr-2">
                    <PatientList lazyParamsForPatients={lazyParams} patientList={patientList} setPatientList={setPatientList} showAddButton={true} />
                </TabPanel>
                <TabPanel header="Specialist" leftIcon="pi pi-user-plus mr-2">
                    <SpecialistList lazyParamsForSpecialists={lazyParams} specialistList={specialistList} setSpecialistList={setSpecialistList} showAddButton={true}/>
                </TabPanel>

                <TabPanel header="Admin" leftIcon="pi pi-key mr-2">
                    <AdminList lazyParamsForAdmins={lazyParams} adminList={adminList} setAdminList={setAdminList}/>
                </TabPanel>
            </TabView>
        </div>
    )
}

export default UserManagement