import FirstGridForCards from "./firstGridForCards"
import SecondGridForCards from "./secondGridForCards"
import ThridGridForCards from "./thirdGridForCards"
import './home.css';
import { TabPanel, TabView } from "primereact/tabview";

const Home = () => {
    return (
        <div className="home">
            <TabView >
                <TabPanel header="Web App" leftIcon="pi pi-globe mr-2">
                    <div className="grid nested-grid">
                        <div className="col-12 xl:col-9">
                            <FirstGridForCards />
                        </div>
                        <div className="col" >
                            <SecondGridForCards />
                        </div>
                        <div className="col-12">
                            <ThridGridForCards />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Mobile App" leftIcon="pi pi-mobile mr-2">
                    <div className="grid nested-grid">
                        <div className="col-12 xl:col-9">
                            <FirstGridForCards />
                        </div>
                        <div className="col" >
                            <SecondGridForCards />
                        </div>
                        <div className="col-12">
                            <ThridGridForCards />
                        </div>
                    </div>
                </TabPanel>
            </TabView>
        </div>
    )
}

export default Home