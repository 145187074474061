import { useState } from "react";
import Conversations from "./Components/conversations"
import "./feedbackSupport.css"
import Messages from "./Components/messages";
import ParticipantInformation from "./Components/participantInformation";

const FeedbackSupport = () => {
    const [conversations, setConversations] = useState<any>([
        {
            id: '1',
            participants: [
                {
                    userId: '123',
                    username: 'John Doe',
                    avatar: 'url-to-avatar',
                    description: "Product Marketing Specialist at Tiga",
                    email: 'johndoe@example.com',
                    phoneNumber: '+1234567890',
                    location: 'New York, USA',
                    company: 'Tiga',
                    note: 'Interested in product development strategies'
                },
                {
                    userId: '456',
                    username: 'Jane Smith',
                    avatar: 'url-to-avatar',
                    description: "Product Marketing Specialist at Tiga",
                    email: 'janesmith@example.com',
                    phoneNumber: '+1234567891',
                    location: 'Los Angeles, USA',
                    company: 'Tiga',
                    note: 'Prefers communication over email'
                }
            ],
            messages: [
                {
                    messageId: 'm1',
                    senderId: '123',
                    text: 'Hey, how are you?',
                    timestamp: '2024-09-12T10:00:00Z',
                    status: 'delivered'
                },
                {
                    messageId: 'm2',
                    senderId: '456',
                    text: 'I am good, thanks! How about you?',
                    timestamp: '2024-09-12T10:01:00Z',
                    status: 'read'
                }
            ],
            lastMessage: {
                messageId: 'm2',
                text: 'I am good, thanks! How about you?',
                timestamp: '2024-09-12T10:01:00Z'
            }
        },
        {
            id: '2',
            participants: [
                {
                    userId: '789',
                    username: 'Alice Wonder',
                    avatar: 'url-to-avatar',
                    description: "Software Engineer at Tiga",
                    email: 'alicewonder@example.com',
                    phoneNumber: '+1234567892',
                    location: 'San Francisco, USA',
                    company: 'Tiga',
                    note: 'Specializes in backend systems'
                },
                {
                    userId: '456',
                    username: 'Jane Smith',
                    avatar: 'url-to-avatar',
                    description: "Product Marketing Specialist at Tiga",
                    email: 'janesmith@example.com',
                    phoneNumber: '+1234567891',
                    location: 'Los Angeles, USA',
                    company: 'Tiga',
                    note: 'Prefers communication over email'
                }
            ],
            messages: [
                {
                    messageId: 'm3',
                    senderId: '789',
                    text: 'Are we still on for the meeting tomorrow?',
                    timestamp: '2024-09-12T14:00:00Z',
                    status: 'delivered'
                },
                {
                    messageId: 'm4',
                    senderId: '456',
                    text: 'Yes, I’ll send you the agenda later today.',
                    timestamp: '2024-09-12T14:05:00Z',
                    status: 'read'
                }
            ],
            lastMessage: {
                messageId: 'm4',
                text: 'Yes, I’ll send you the agenda later today.',
                timestamp: '2024-09-12T14:05:00Z'
            }
        },
        {
            id: '3',
            participants: [
                {
                    userId: '202',
                    username: 'Diana Brown',
                    avatar: 'url-to-avatar',
                    description: "Software Engineer at Tiga",
                    email: 'dianabrown@example.com',
                    phoneNumber: '+1234567893',
                    location: 'Chicago, USA',
                    company: 'Tiga',
                    note: 'Specializes in frontend development'
                },
                {
                    userId: '456',
                    username: 'Jane Smith',
                    avatar: 'url-to-avatar',
                    description: "Product Marketing Specialist at Tiga",
                    email: 'janesmith@example.com',
                    phoneNumber: '+1234567891',
                    location: 'Los Angeles, USA',
                    company: 'Tiga',
                    note: 'Prefers communication over email'
                }
            ],
            messages: [
                {
                    messageId: 'm5',
                    senderId: '202',
                    text: 'Hey Diana, are you coming to the event tonight?',
                    timestamp: '2024-09-12T16:30:00Z',
                    status: 'sent'
                },
                {
                    messageId: 'm6',
                    senderId: '456',
                    text: 'Yes, I’ll be there around 7 PM. See you soon!',
                    timestamp: '2024-09-12T16:35:00Z',
                    status: 'delivered'
                },
            ],
            lastMessage: {
                messageId: 'm6',
                text: 'Yes, I’ll be there around 7 PM. See you soon!',
                timestamp: '2024-09-12T16:35:00Z'
            }
        },
        {
            id: '4',
            participants: [
                {
                    userId: '231',
                    username: 'Jane Doe',
                    avatar: 'url-to-avatar',
                    description: "Quality Engineer at Tiga",
                    email: 'janedoe@example.com',
                    phoneNumber: '+1234567893',
                    location: 'Chicago, USA',
                    company: 'Tiga',
                    note: 'Specializes in quality engineering'
                },
                {
                    userId: '456',
                    username: 'Jane Smith',
                    avatar: 'url-to-avatar',
                    description: "Product Marketing Specialist at Tiga",
                    email: 'janesmith@example.com',
                    phoneNumber: '+1234567891',
                    location: 'Los Angeles, USA',
                    company: 'Tiga',
                    note: 'Prefers communication over email'
                }
            ],
            messages: [
                {
                    messageId: 'm5',
                    senderId: '231',
                    text: 'Hey Diana, are you coming to the event tonight?',
                    timestamp: '2024-09-12T16:30:00Z',
                    status: 'sent'
                },
                {
                    messageId: 'm6',
                    senderId: '456',
                    text: 'Yes, I’ll be there around 7 PM. See you soon!',
                    timestamp: '2024-09-12T16:35:00Z',
                    status: 'delivered'
                },
            ],
            lastMessage: {
                messageId: 'm6',
                text: 'Yes, I’ll be there around 7 PM. See you soon!',
                timestamp: '2024-09-12T16:35:00Z'
            }
        },
        {
            id: '5',
            participants: [
                {
                    userId: '222',
                    username: 'Mehmet Solak',
                    avatar: 'url-to-avatar',
                    description: "Candidate Software Engineer at Tiga",
                    email: 'mehmetsolak@example.com',
                    phoneNumber: '+1234567893',
                    location: 'Chicago, USA',
                    company: 'Tiga',
                    note: 'Specializes in frontend & backend engineering'
                },
                {
                    userId: '456',
                    username: 'Jane Smith',
                    avatar: 'url-to-avatar',
                    description: "Product Marketing Specialist at Tiga",
                    email: 'janesmith@example.com',
                    phoneNumber: '+1234567891',
                    location: 'Los Angeles, USA',
                    company: 'Tiga',
                    note: 'Prefers communication over email'
                }
            ],
            messages: [
                {
                    messageId: 'm5',
                    senderId: '222',
                    text: 'Hey Diana, are you coming to the event tonight?',
                    timestamp: '2024-09-12T16:30:00Z',
                    status: 'sent'
                },
                {
                    messageId: 'm6',
                    senderId: '456',
                    text: 'Yes, I’ll be there around 7 PM. See you soon!',
                    timestamp: '2024-09-12T16:35:00Z',
                    status: 'delivered'
                },
            ],
            lastMessage: {
                messageId: 'm6',
                text: 'Yes, I’ll be there around 7 PM. See you soon!',
                timestamp: '2024-09-12T16:35:00Z'
            }
        },
        {
            id: '6',
            participants: [
                {
                    userId: '333',
                    username: 'Huseyn Allahyarov',
                    avatar: 'url-to-avatar',
                    description: "Frontend Engineer at Tiga",
                    email: 'huseynallahyarov@example.com',
                    phoneNumber: '+1234567893',
                    location: 'Chicago, USA',
                    company: 'Tiga',
                    note: 'Specializes in frontend engineering'
                },
                {
                    userId: '456',
                    username: 'Jane Smith',
                    avatar: 'url-to-avatar',
                    description: "Product Marketing Specialist at Tiga",
                    email: 'janesmith@example.com',
                    phoneNumber: '+1234567891',
                    location: 'Los Angeles, USA',
                    company: 'Tiga',
                    note: 'Prefers communication over email'
                }
            ],
            messages: [
                {
                    messageId: 'm5',
                    senderId: '333',
                    text: 'Hey Diana, are you coming to the event tonight?',
                    timestamp: '2024-09-12T16:30:00Z',
                    status: 'sent'
                },
                {
                    messageId: 'm6',
                    senderId: '456',
                    text: 'Yes, I’ll be there around 7 PM. See you soon!',
                    timestamp: '2024-09-12T16:35:00Z',
                    status: 'delivered'
                },
            ],
            lastMessage: {
                messageId: 'm6',
                text: 'Yes, I’ll be there around 7 PM. See you soon!',
                timestamp: '2024-09-12T16:35:00Z'
            }
        },
        {
            id: '7',
            participants: [
                {
                    userId: '444',
                    username: 'Emre Yılmaz',
                    avatar: 'url-to-avatar',
                    description: "Senior Backend Developer at Tiga",
                    email: 'emreyilmaz@example.com',
                    phoneNumber: '+1234567894',
                    location: 'Istanbul, Turkey',
                    company: 'Tiga',
                    note: 'Works with microservices architecture'
                },
                {
                    userId: '456',
                    username: 'Jane Smith',
                    avatar: 'url-to-avatar',
                    description: "Product Marketing Specialist at Tiga",
                    email: 'janesmith@example.com',
                    phoneNumber: '+1234567891',
                    location: 'Los Angeles, USA',
                    company: 'Tiga',
                    note: 'Prefers communication over email'
                }
            ],
            messages: [
                {
                    messageId: 'm7',
                    senderId: '444',
                    text: 'Hey Jane, I just wanted to touch base with you regarding the upcoming release. We’ve been working hard on the backend optimizations, and I wanted to ensure that everything is aligned with the marketing team’s timeline. Can we schedule a call to go over the final details?',
                    timestamp: '2024-09-12T18:00:00Z',
                    status: 'delivered'
                },
                {
                    messageId: 'm8',
                    senderId: '456',
                    text: 'Hi Emre, thank you for reaching out! Yes, we definitely need to sync up on that. We’ve planned a big marketing push for next week, and it’s crucial that everything is in place by then. I can arrange a call for tomorrow morning, if that works for you. Let me know your availability, and we’ll get it on the calendar.',
                    timestamp: '2024-09-12T18:15:00Z',
                    status: 'read'
                }
            ],
            lastMessage: {
                messageId: 'm8',
                text: 'Hi Emre, thank you for reaching out! Yes, we definitely need to sync up on that. We’ve planned a big marketing push for next week, and it’s crucial that everything is in place by then. I can arrange a call for tomorrow morning, if that works for you. Let me know your availability, and we’ll get it on the calendar.',
                timestamp: '2024-09-12T18:15:00Z'
            }
        },
        {
            id: '8',
            participants: [
                {
                    userId: '555',
                    username: 'Fatma Özdemir',
                    avatar: 'url-to-avatar',
                    description: "Project Manager at Tiga",
                    email: 'fatmaozdemir@example.com',
                    phoneNumber: '+1234567895',
                    location: 'Ankara, Turkey',
                    company: 'Tiga',
                    note: 'Focuses on cross-functional project coordination'
                },
                {
                    userId: '456',
                    username: 'Jane Smith',
                    avatar: 'url-to-avatar',
                    description: "Product Marketing Specialist at Tiga",
                    email: 'janesmith@example.com',
                    phoneNumber: '+1234567891',
                    location: 'Los Angeles, USA',
                    company: 'Tiga',
                    note: 'Prefers communication over email'
                }
            ],
            messages: [
                {
                    messageId: 'm9',
                    senderId: '555',
                    text: 'Hi Jane, I hope you’re doing well. I wanted to give you an update on the project timeline. We’ve encountered a few unexpected challenges with the development team, and there’s a slight delay in the deliverables. However, we’re confident that we can catch up by next week. I wanted to keep you in the loop and see if there are any adjustments we need to make on the marketing side.',
                    timestamp: '2024-09-12T19:00:00Z',
                    status: 'delivered'
                },
                {
                    messageId: 'm10',
                    senderId: '456',
                    text: 'Hi Fatma, thanks for the update. It’s good to know what’s going on. As far as marketing is concerned, we’ve already begun prepping for the launch, but if there’s a delay on the development side, we can adjust our schedule accordingly. Let’s stay in touch and coordinate closely over the next few days to make sure everything goes smoothly. I’ll loop in the design team as well so we can adjust any assets if needed.',
                    timestamp: '2024-09-12T19:20:00Z',
                    status: 'read'
                }
            ],
            lastMessage: {
                messageId: 'm10',
                text: 'Hi Fatma, thanks for the update. It’s good to know what’s going on. As far as marketing is concerned, we’ve already begun prepping for the launch, but if there’s a delay on the development side, we can adjust our schedule accordingly. Let’s stay in touch and coordinate closely over the next few days to make sure everything goes smoothly. I’ll loop in the design team as well so we can adjust any assets if needed.',
                timestamp: '2024-09-12T19:20:00Z'
            }
        }
    ]);

    const [selectedConversation, setSelectedConversation] = useState<any>(0);

    return (
        <div className="grid h-screen overflow-hidden">
            <div className="col-3 h-full">
                <Conversations selectedConversation={selectedConversation} setSelectedConversation={setSelectedConversation} conversations={conversations} />
            </div>
            <div className="col-6 h-full">
                <Messages conversations={conversations} setConversations={setConversations} selectedConversation={selectedConversation} />
            </div>
            <div className="col-3 h-full">
                <ParticipantInformation conversations={conversations} selectedConversation={selectedConversation} />
            </div>
        </div>
    )
}

export default FeedbackSupport