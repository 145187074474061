import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { useEffect, useState } from "react";
import AddSpecialistDailog from "./addSpecialistDailog";
import { BlockUI } from "primereact/blockui";
import { useGetSpecialistListQuery } from "../../Hooks/useGetDataQuery";

const SpecialistList = ({ lazyParamsForSpecialists, specialistList, setSpecialistList, showAddButton }: any) => {
    const columns = [
        {
            field: "firstName",

        },
        {
            field: "lastName",

        },
        {
            field: "email",

        },
        {
            field: "dateCreated",

        },
        {
            field: "action"
        }
    ];

    const { isSuccess, isError, data } = useGetSpecialistListQuery(showAddButton);

    useEffect(() => {
        if (isSuccess && showAddButton) {
            setSpecialistList(data.data)
            setBlockUI(false)
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError && showAddButton) {
            setBlockUI(false)
        }
    }, [isError]);

    const [blockUI, setBlockUI] = useState(showAddButton);

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        firstName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        lastName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        dateCreated: { value: null, matchMode: FilterMatchMode.DATE_IS },
    });

    const [addSpecialistDialogVisible, setAddSpecialistDialogVisible] = useState(false);

    const dateBodyTemplate = (rowData: any) => {
        const date = new Date(rowData.dateCreated)
        if (date) {
            const formattedDate = date.toLocaleDateString("tr-TR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            });
            return formattedDate.replace(", ", " ")
        };
    }

    const dateFilterTemplate = (options: any) => {
        return <Calendar value={options.value} onChange={(e) => options.filterApplyCallback(e.value, options.index)} />;
    };

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const header = () => {
        return (
            <div className="flex justify-content-end gap-3">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search by first or last name" />
                </IconField>

                {showAddButton && <Button label="Add New" icon="pi pi-plus" rounded severity="danger" style={{ backgroundColor: "#E3506B" }}
                    onClick={() => setAddSpecialistDialogVisible(true)} />}
            </div>
        );
    };

    // const authorityBodyTemplate = (rowData: any) => {
    //     const obj = rowData.authority ? rowData.authority : rowData
    //     const styleForTag = obj === "Partial" ? {
    //         backgroundColor: "#FBF9F4",
    //         color: "#DA9273",
    //         borderColor: "#DA9273",
    //         borderWidth: "1px",
    //         borderStyle: 'solid',
    //         borderRadius: "1rem"

    //     } : {
    //         backgroundColor: "#EBF9F1",
    //         color: "#41BC63",
    //         borderColor: "#41BC63",
    //         borderWidth: "1px",
    //         borderStyle: 'solid',
    //         borderRadius: "1rem"
    //     }

    //     return <Tag style={styleForTag} value={obj} ></Tag>;
    // };

    // const authorityFilterTemplate = (options: any) => {
    //     return (
    //         <Dropdown
    //             value={options.value}
    //             options={authorityList}
    //             onChange={(e) => options.filterApplyCallback(e.value)}
    //             className="p-column-filter"
    //             itemTemplate={authorityBodyTemplate}
    //         />
    //     );
    // };

    const actionButtons = (rowData: any) => {
        return (
            <div className="flex flex-row flex-nowrap ">
                <Button className="m-1" rounded text icon="pi pi-pen-to-square" severity="secondary" size="small" />
                <Button className="m-1" rounded text icon="pi pi-trash" severity="secondary" size="small" />
            </div>
        )
    }

    const dynamicColumns = columns.map((col: any) => {
        if (col.field === "firstName") {
            return (
                <Column
                    key="firstName"
                    field={col.field}
                    header="First Name"
                    dataType='text'
                    sortable
                    filter
                />
            );
        }
        else if (col.field === "lastName") {
            return (
                <Column
                    key="lastName"
                    field={col.field}
                    header="Last Name"
                    dataType='text'
                    sortable
                    filter
                />
            );
        }
        else if (col.field === "email") {
            return (
                <Column
                    key="email"
                    field={col.field}
                    header="Email"
                    dataType='text'
                    sortable
                    filter
                />
            );
        } else if (col.field === "dateCreated") {
            return (
                <Column
                    key="dateCreated"
                    field={col.field}
                    header="Create Date"
                    dataType='date'
                    sortable
                    body={dateBodyTemplate}
                    filter
                    filterElement={dateFilterTemplate}
                    showApplyButton={false}
                />
            );
        }
        else {
            return (
                <Column
                    key="action"
                    field={col.field}
                    header="Actions"
                    body={actionButtons}
                />
            );
        }
    });

    return (
        <div className="m-0">
            <BlockUI
                blocked={blockUI}
                fullScreen
                style={{ backgroundColor: 'transparent' }}
                template={<i className='pi pi-spin pi-spinner' style={{ fontSize: '3rem' }}></i>}
            >
                <DataTable paginator rows={lazyParamsForSpecialists.rows} value={specialistList} filters={filters} tableStyle={{ minWidth: '50rem' }} header={header} globalFilterFields={['firstName', 'lastName']} >
                    {dynamicColumns}
                </DataTable>
                <AddSpecialistDailog setSpecialistList={setSpecialistList} addSpecialistDialogVisible={addSpecialistDialogVisible} setAddSpecialistDialogVisible={setAddSpecialistDialogVisible} />
            </BlockUI>
        </div>
    )
}

export default SpecialistList