import { useMutation, useQuery } from "@tanstack/react-query";
import { MobitheraAdminService } from "../Services/mobitheraAdminService";


export const useLoginMutation = () =>
  useMutation({
    mutationFn: (credentials: any) => new MobitheraAdminService().login(credentials),
  })

export const useTokenCheckMutation = () =>
  useMutation({
    mutationFn: () => new MobitheraAdminService().tokenCheck(),
  })

export const useGetPatienListQuery = (showButton:any) => {
  return useQuery({
    queryKey: ['patientList'],
    queryFn: () => new MobitheraAdminService().getAllPatients(showButton),
  })
}

export const useGetPatientsByHospitalMutation = () =>
  useMutation({
    mutationFn: (hospitalId: any) => new MobitheraAdminService().getAllPatientsByHospital(hospitalId),
  })

export const useAddPatientMutation = () =>
  useMutation({
    mutationFn: (patient: any) => new MobitheraAdminService().addPatient(patient),
  })

export const useGetSpecialistListQuery = (showButton:any) => {
  return useQuery({
    queryKey: ['specialistList'],
    queryFn: () => new MobitheraAdminService().getAllSpecialists(showButton),
  })
}

export const useGetSpecialistsByHospitalMutation = () =>
  useMutation({
    mutationFn: (hospitalId: any) => new MobitheraAdminService().getAllSpecialistsByHospital(hospitalId),
  })

export const useAddSpecialistMutation = () =>
  useMutation({
    mutationFn: (specialist: any) => new MobitheraAdminService().addSpecialist(specialist),
  })

  export const useGetAdminListQuery = () => {
    return useQuery({
      queryKey: ['adminList'],
      queryFn: () => new MobitheraAdminService().getAllAdmins(),
    })
  }

export const useGetHospitalListQuery = () => {
  return useQuery({
    queryKey: ['hospitalList'],
    queryFn: () => new MobitheraAdminService().getAllHospitals(),
  })
}

export const useLogoutMutation = () =>
  useMutation({
    mutationFn: () => new MobitheraAdminService().logout(),
  })
