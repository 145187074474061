import { Accordion, AccordionTab } from "primereact/accordion";

const ParticipantInformation = ({ conversations, selectedConversation } : any) => {
    return (
        <div style={{wordWrap:"break-word"}} className="border-2 border-200	border-round-xl p-2 h-full overflow-y-auto overflow-x-hidden">
            <Accordion className="" multiple activeIndex={[0, 1, 2]}>
                <AccordionTab header="Key Information">
                    <div className="flex flex-column gap-3 w-full">
                        <span >{conversations[selectedConversation]?.participants[0]?.email}</span>
                        <span >{conversations[selectedConversation]?.participants[0]?.phoneNumber}</span>
                        <span >{conversations[selectedConversation]?.participants[0]?.location}</span>
                    </div>
                </AccordionTab>
                <AccordionTab header="Company">
                    <div className="flex flex-column gap-3">
                        <span>{conversations[selectedConversation]?.participants[0]?.company}</span>
                        <span>{conversations[selectedConversation]?.participants[0]?.location}</span>
                    </div>
                </AccordionTab>
                <AccordionTab header="Notes">
                    <div className="flex flex-column gap-3">
                        <span>{conversations[selectedConversation]?.participants[0]?.note}</span>
                    </div>
                </AccordionTab>
            </Accordion>
        </div>
    )
}

export default ParticipantInformation;