export const PeriodList = [
    {
      name: "morning",
      value: "Morning",
    },
    {
      name: "afternoon",
      value: "Afternoon",
    },
    {
      name: "evening",
      value: "Evening",
    },
  ]