import { Avatar } from "primereact/avatar";
import { Menubar } from "primereact/menubar"
import { SplitButton } from "primereact/splitbutton";
import { TieredMenu } from "primereact/tieredmenu"
import { Outlet, useNavigate } from "react-router-dom"
import './mainLayout.css';
import { useEffect, useState } from "react";
import { MenuItem } from "primereact/menuitem";
import { LazyParams } from "../Services/lazyParams";
import { useLogoutMutation, useTokenCheckMutation } from "../Hooks/useGetDataQuery";
import { useToastContext } from "../Lib/Context/toastContext";
import { BlockUI } from "primereact/blockui";

const MainLayout = () => {
  const navigate = useNavigate();
  const { showToast } = useToastContext();

  const {
    mutate: tokenCheckMutation,
    isSuccess: isSuccessTokenCheck,
    isError: isErrorTokenCheck,
  } = useTokenCheckMutation();

  const {
    mutate: logoutMutation,
  } = useLogoutMutation();


  const [pathname, setPathName] = useState<any>();
  const [doctorName, setDoctorName] = useState<any>("DR. Natashiya");
  const [authority, setAuthority] = useState<any>("full");
  const [blockUI, setBlockUI] = useState<any>();

  const [lazyParams, setLazyParams] = useState<LazyParams>({
    first: 0,
    rows: 10,
    page: 1,
    sortField: "registerDate",
    sortOrder: 1,
    filters: {},
  });

  useEffect(() => {
    /* eslint-disable no-restricted-globals */
    setPathName(location.pathname.substring(location.pathname.lastIndexOf("/"), location?.pathname.length));
    setBlockUI(true)
    tokenCheckMutation()
  }, []);

  useEffect(() => {
    if (isErrorTokenCheck) {
      showToast({ severity: 'error', summary: 'Error', detail: 'Your token is expired' });
      setBlockUI(false)
      signOut()
    }

  }, [isErrorTokenCheck]);

  useEffect(() => {
    if (isSuccessTokenCheck) {
      setBlockUI(false)
    }

  }, [isSuccessTokenCheck]);


  useEffect(() => {
    if (pathname) {
      if (pathname === "/mobitheraAdmin") {
        navigate({ pathname: "/mobitheraAdmin/home" });
      }
      else if (pathname === "/allClinics") {
        navigate({ pathname: "/mobitheraAdmin/reportAnalytics/allClinics" })
      }
      else {
        navigate({ pathname: "/mobitheraAdmin" + pathname });
      }
    }
  }, [pathname]);

  const signOut = () => {
    logoutMutation()
    navigate({ pathname: "/" });
  };


  const itemsForMenu: MenuItem[] = [
    {
      label: "Home",
      command: () => navigate({ pathname: "/mobitheraAdmin/home" }),
      icon: <i className="pi pi-home" style={{ fontSize: '1rem', marginRight: "5%" }}></i>,
      className: window.location.pathname === "/mobitheraAdmin/home" ? "p-focus" : "",
      style: { paddingTop: "2rem" }
    },
    {
      label: "User Management",
      icon: <i className="pi pi-user" style={{ fontSize: '1rem', marginRight: "5%" }}></i>,
      command: () => navigate({ pathname: "/mobitheraAdmin/userManagement" }),
      className: window.location.pathname === "/mobitheraAdmin/userManagement" ? "p-focus" : "",
      style: authority === "full" || authority === "commercial" ? { paddingTop: "1rem" } : { display: "none" }
    },
    {
      label: "Content Management",
      icon: <i className="pi pi-book" style={{ fontSize: '1rem', marginRight: "5%" }}></i>,
      command: () => navigate({ pathname: "/mobitheraAdmin/contentManagement" }),
      className: window.location.pathname === "/mobitheraAdmin/contentManagement" ? "p-focus" : "",
      style: authority === "full" || authority === "commercial" || authority === "r&d" ? { paddingTop: "1rem" } : { display: "none" }
    },
    {
      label: "Report & Analytics",
      icon: <i className="pi pi-chart-bar" style={{ fontSize: '1rem', marginRight: "5%" }}></i>,
      command: () => navigate({ pathname: "/mobitheraAdmin/reportAnalytics" }),
      className: window.location.pathname === "/mobitheraAdmin/reportAnalytics" || window.location.pathname === "/mobitheraAdmin/reportAnalytics/allClinics" ? "p-focus" : "",
      style: authority === "full" || authority === "commercial" ? { paddingTop: "1rem" } : { display: "none" }
    },
    {
      label: "Feedback & Support",
      icon: <i className="pi pi-inbox" style={{ fontSize: '1rem', marginRight: "5%" }}></i>,
      command: () => navigate({ pathname: "/mobitheraAdmin/feedbackSupport" }),
      className: window.location.pathname === "/mobitheraAdmin/feedbackSupport" ? "p-focus" : "",
      style: authority === "full" || authority === "techicalSupport" ? { paddingTop: "1rem" } : { display: "none" }
    },
  ];

  const itemsForSplitButton: MenuItem[] = [
    {
      label: "Sign Out",
      command: () => signOut(),
    },
  ];

  const start = (
    <img alt="logo" src="/brand.png" height="40" className="mr-2"></img>
  );

  const end = (
    <div className="flex align-items-center gap-1">
      <Avatar
        image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png"
        shape="circle"
        size="large"
      />
      <h3>{doctorName}</h3>
      <SplitButton
        model={itemsForSplitButton}
        severity="secondary"
        text
        buttonClassName="split-button-main"
        size="small"
      />
    </div>
  );



  return (
    <div >
      <BlockUI
        blocked={blockUI}
        fullScreen
        style={{ backgroundColor: 'transparent' }}
        template={<i className='pi pi-spin pi-spinner' style={{ fontSize: '3rem' }}></i>}
      >
        <div className='flex flex-column gap-1 h-screen'>
          <Menubar start={start} end={end} className='border-noround' style={{ backgroundColor: "#EBF2FF", border: "#EBF2FF" }} />

          <div className='flex flex-row' style={{ flexGrow: 1, overflow: 'hidden' }}>
            <TieredMenu model={itemsForMenu} className='h-full border-noround' style={{ backgroundColor: "#EBF2FF", border: "#EBF2FF" }} />
            <div className="h-full w-full flex flex-column m-0 flex-nowrap relative py-6 step2"
              style={{ overflowY: 'auto', paddingLeft: "6rem", paddingRight: "6rem" }}>
              <Outlet context={{
                lazyParams,
              }} />
            </div>
          </div>
        </div>
      </BlockUI>
    </div>
  )
}

export default MainLayout