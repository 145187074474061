import { Card } from "primereact/card"
import UserLineChart from "../Charts/userLineChart";

const FirstGridForCards = () => {

    const footerForFirstCard = (
        <span className="text-sm">
            <span style={{ color: "#00B69B" }}>
                <i className="pi pi-angle-double-up"></i> 8.5%
            </span> Up from yesterday
        </span>
    );
    
    const footerForSecondCard = (
        <span className="text-sm">
            <span style={{ color: "#B60000" }}>
                <i className="pi pi-angle-double-down"></i> 8.5%
            </span> Down from yesterday
        </span>
    );
    
    const footerForThirdCard = (
        <span className="text-sm">
            <span style={{ color: "#B60000" }}>
                <i className="pi pi-angle-double-down"></i> 8.5%
            </span> Down from yesterday
        </span>
    );
    
    const footerForFourthCard = (
        <span className="text-sm">
            <span style={{ color: "#00B69B" }}>
                <i className="pi pi-angle-double-up"></i> 8.5%
            </span> Up from yesterday
        </span>
    );

    return (
        <div className="grid">
            <div className="col ">
                <Card className="h-full" footer={footerForFirstCard}>
                    <div className="flex flex-column">
                        <div className="flex flex-row justify-content-between">
                            <div className=" flex flex-column gap-2">
                                <span className="text-md">Total User</span>
                                <span className="text-lg font-bold">40,689</span>
                            </div>
                            <img src="/Icon.svg" />
                        </div>
                    </div>
                </Card>
            </div>
            <div className="col">
                <Card className="h-full" footer={footerForSecondCard}>
                    <div className="flex flex-row justify-content-between">
                        <div className=" flex flex-column gap-2">
                            <span className="text-md">Bounce Rate</span>
                            <span className="text-lg font-bold">40,689</span>
                        </div>
                        <img src="/Icon2.svg" />
                    </div>
                </Card>
            </div>
            <div className="col">
                <Card className="h-full" footer={footerForThirdCard}>
                    <div className="flex flex-row justify-content-between">
                        <div className=" flex flex-column gap-2">
                            <span className="text-md">Session</span>
                            <span className="text-lg font-bold">40,689</span>
                        </div>
                        <img src="/Icon3.svg" />
                    </div>
                </Card>
            </div>
            <div className="col">
                <Card className="h-full" footer={footerForFourthCard}>
                    <div className="flex flex-row justify-content-between">
                        <div className=" flex flex-column gap-2">
                            <span className="text-md">Session Duration</span>
                            <span className="text-lg font-bold">40,689</span>
                        </div>
                        <img src="/Icon4.svg" />
                    </div>
                </Card>
            </div>
            <div className="col-12 pb-0">
                <Card className="h-full" title="User Statisticks">
                    <UserLineChart />
                </Card>
            </div>
        </div>
    )
}

export default FirstGridForCards