import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

// Register necessary components
Chart.register(ArcElement, Tooltip, Legend);

const ClinickListTinyDonutChart = ({ selectedClinicIndex, index }: any) => {
    const data = {
        datasets: [
            {
                data: [87, 13], 
                backgroundColor: ['#FF6384', '#E0E0E0'], 
                borderWidth: 0, 
            },
        ],
    };

    const options = {
        cutout: '80%',
        plugins: {
            tooltip: { enabled: false },
        },
    };

    return (
        <div className='relative flex justify-content-center w-full h-5rem'>
            <Doughnut data={data} options={options} />
            <div
                style={{
                    position: 'absolute',
                    top: '55%',
                    left: '53%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    color: selectedClinicIndex === index ? "white" : 'black',
                }}
            >
                87%
            </div>
        </div>
    );
};

export default ClinickListTinyDonutChart;