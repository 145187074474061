import { Card } from "primereact/card"
import UserLineChart from "../../Charts/userLineChart"
import FirstGridForCardsClinic from "./firstGridForCardsClinic"
import SecondGridForCardsClinic from "./secondGridForCardsClinic"
import ClinickList from "./clinickList"
import { useEffect, useState } from "react"
import { Button } from "primereact/button"
import PatientDialog from "./Dialogs/patientDialog"
import SpecialistDialog from "./Dialogs/specialistDialog"
import { useGetHospitalListQuery } from "../../Hooks/useGetDataQuery"
import { BlockUI } from "primereact/blockui"

const AllClinics = () => {
    const [clinicks, setClinicks] = useState<any>()
    const [selectedClinicIndex, setSelectedClinicIndex] = useState<any>();
    const [patientDialogVisible, setPatientDialogVisible] = useState<any>(false);
    const [specialistDialogVisible, setSpecialistDialogVisible] = useState<any>(false);

    const [blockUI, setBlockUI] = useState(true);
    const { isSuccess, isError, data } = useGetHospitalListQuery();

    useEffect(() => {
        if (isSuccess) {
            setClinicks(data.data)
            setBlockUI(false)
            setSelectedClinicIndex(0)
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            setBlockUI(false)
        }
    }, [isError]);



    return (
        <div>
            <BlockUI
                blocked={blockUI}
                fullScreen
                style={{ backgroundColor: 'transparent' }}
                template={<i className='pi pi-spin pi-spinner' style={{ fontSize: '3rem' }}></i>}
            >
                <div className="flex flex-row justify-content-between align-items-center ml-2 w-full">
                    <div className="flex flex-column justify-content-center align-items-center">
                        <Button label="Add New" icon="pi pi-plus" rounded severity="danger" style={{ backgroundColor: "#E3506B" }} />
                    </div>
                    {clinicks && <span className="text-2xl font-bold">{clinicks[selectedClinicIndex].name}</span>}
                    
                </div>

                <div className="grid">
                    <div className="col-12 xl:col-4">
                        <ClinickList clinicks={clinicks} selectedClinicIndex={selectedClinicIndex} setSelectedClinicIndex={setSelectedClinicIndex} />
                    </div>

                    <div className="col">
                        <div className="grid nested-grid">
                            <div className="col-12 xl:col-6">
                                <FirstGridForCardsClinic clinicks={clinicks} selectedClinicIndex={selectedClinicIndex}
                                    setPatientDialogVisible={setPatientDialogVisible} setSpecialistDialogVisible={setSpecialistDialogVisible} />
                            </div>
                            <div className="col">
                                <SecondGridForCardsClinic />
                            </div>
                            <div className="col-12">
                                <Card title="User Statisticks" className="h-full">
                                    <UserLineChart />
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                <PatientDialog patientDialogVisible={patientDialogVisible} setPatientDialogVisible={setPatientDialogVisible} clinicks={clinicks} selectedClinicIndex={selectedClinicIndex}/>
                <SpecialistDialog specialistDialogVisible={specialistDialogVisible} setSpecialistDialogVisible={setSpecialistDialogVisible} clinicks={clinicks} selectedClinicIndex={selectedClinicIndex}/>
            </BlockUI>
        </div>

    )

}

export default AllClinics