import { Button } from "primereact/button";
import { DataView } from "primereact/dataview";
import { Divider } from "primereact/divider";

const Conversations = ({ conversations, selectedConversation, setSelectedConversation } : any) => {
    const onConversationSelect = (conversation : any) => {
        setSelectedConversation(conversations.findIndex((conv:any) => conv.id === conversation.id));
    }

    const header = (
        <div className="flex flex-column p-3">
            <div className="flex justify-content-between align-items-center">
                <span className="text-2xl font-medium">All Conversations</span>
                <Button link icon={<i className="pi pi-sort-amount-down text-2xl text-black-alpha-90"></i>}  />
            </div>
            <span className="font-light text-orange-900	text-md">You have {conversations?.length} conversations</span>
            <Divider />
        </div>
    )

    const itemTemplate = (conversation : any) => {
        return (
            <div onClick={() => onConversationSelect(conversation)} className={`cursor-pointer w-full border-round-lg ${conversations[selectedConversation]?.id === conversation?.id ? "bg-blue-900 fadein animation-duration-400" : ""}`}>
                <div className="flex flex-column gap-4 p-3">
                    <span className={`font-medium text-md ${conversations[selectedConversation]?.id === conversation?.id ? "text-white" : "text-black-alpha-90"}`}>
                        {conversation?.participants?.map((participant : any) => participant.username).join(' - ')}
                    </span>
                    <span className={`font-light truncate-multi-line text-md ${conversations[selectedConversation]?.id === conversation?.id ? "text-white" : "text-600"}`}>
                        {conversation?.lastMessage?.text}
                    </span>
                </div>
            </div>
        )
    }

    return (
        <div className="border-2 border-200	border-round-xl p-2 overflow-y-auto h-full">
            <DataView className="feedback-support-dataview" value={conversations} header={header} itemTemplate={itemTemplate} layout={"list"} />
        </div>
    )
}

export default Conversations;