import { Card } from "primereact/card"
import GeneralAdherenceLineChart from "../../Charts/generalAdherenceLineChart"
import SuccessBarChart from "../../Charts/successBarChart"

const FirstGridForCardsClinic = ({ clinicks, selectedClinicIndex, setPatientDialogVisible, setSpecialistDialogVisible }: any) => {


    const footerForFirstCard = (
        <span className="text-sm">
            <span style={{ color: "#00B69B" }}>
                <i className="pi pi-angle-double-up"></i> 8.5%
            </span> Up from yesterday
        </span>
    );

    const footerForSecondCard = (
        <span className="text-sm">
            <span style={{ color: "#B60000" }}>
                <i className="pi pi-angle-double-down"></i> 8.5%
            </span> Down from yesterday
        </span>
    );

    return (
        <>
            {clinicks && <div className="grid">
                <div className="col">
                    <Card className="h-full" footer={footerForFirstCard}>
                        <div className="flex flex-column">
                            <div className="flex flex-row justify-content-between">
                                <div className=" flex flex-column gap-2">
                                    <span className="text-md">Total Patients</span>
                                    <span className="text-lg font-bold">{77}</span>
                                </div>
                                <img src="/Icon.svg" className="clickableImg" onClick={() => { setPatientDialogVisible(true) }} />
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="col">
                    <Card className="h-full" footer={footerForSecondCard}>
                        <div className="flex flex-column">
                            <div className="flex flex-row justify-content-between">
                                <div className=" flex flex-column gap-2">
                                    <span className="text-md">Total Specialists</span>
                                    <span className="text-lg font-bold">{clinicks[selectedClinicIndex].consultantsCount}</span>
                                </div>
                                <img src="/Icon.svg" className="clickableImg" onClick={() => { setSpecialistDialogVisible(true) }} />
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="col-12">
                    <Card>
                        <GeneralAdherenceLineChart contentManagmentInput={false} />
                    </Card>

                </div>
                <div className="col-12 pb-0">
                    <Card >
                        <SuccessBarChart contentManagmentInput={false} />
                    </Card>
                </div>
            </div>}
        </>


    )
}

export default FirstGridForCardsClinic