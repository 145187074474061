import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, Tooltip, Filler, CategoryScale } from 'chart.js';

ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, Filler, CategoryScale);

const UserLineChart = () => {


  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem: any) {
            return `${tooltipItem.raw} Users`;
          }
        }
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 1000,
      },
    },
  };

  const data = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    datasets: [
      {
        label: 'User Statistics',
        data: [300, 500, 450, 1000, 400, 500, 200, 400, 600, 350, 450, 500],
        fill: true,
        backgroundColor: (context: any) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {

            return null;
          }


          const gradient = ctx.createLinearGradient(0, 0, 0, chartArea.bottom);
          gradient.addColorStop(0, 'rgba(67, 121, 238, 0.5)');
          gradient.addColorStop(1, 'rgba(67, 121, 238, 0)');
          return gradient;
        },
        borderColor: 'rgba(67, 121, 238, 1)',
        borderWidth: 2,
        pointBackgroundColor: 'rgba(67, 121, 238, 1)',
        tension: 0.2,
      },
    ],
  };
  return (
    <div className='flex justify-content-center w-full h-20rem'>
      <Line data={data} options={options} />
    </div>
  );
}

export default UserLineChart