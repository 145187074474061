import { BlockUI } from "primereact/blockui"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Message } from "primereact/message"
import { Password } from "primereact/password"
import { SelectButton } from "primereact/selectbutton"
import { useEffect, useState } from "react"
import { useAddSpecialistMutation } from "../../Hooks/useGetDataQuery"
import { useToastContext } from "../../Lib/Context/toastContext"

const AddSpecialistDailog = ({ setSpecialistList, addSpecialistDialogVisible, setAddSpecialistDialogVisible }: any) => {
    const { showToast } = useToastContext();

    const {
        mutate: addSpecialistMutation,
        isSuccess: isSuccessSpecialistMutation,
        isError: isErrorSpecialistMutation,
    } = useAddSpecialistMutation();

    const [specialistName, setSpecialistName] = useState<any>()
    const [specialistSurname, setspecialistSurname] = useState<any>()
    const [specialistEmail, setSpecialistEmail] = useState<any>()
    const [specialistPassword, setSpecialistPassword] = useState<any>()
    const [specialistHospital, setSpecialistHospital] = useState<any>()
    const [specialistSpeciality, setSpecialistSpeciality] = useState<any>()

    const [blockUI, setBlockUI] = useState(false);

    const genderOptions = ['Male', 'Female'];
    const [gender, setGender] = useState(genderOptions[0]);


    const [nameIsNotGiven, setNameIsNotGiven] = useState<any>(false)
    const [surnameIsNotGiven, setSurnameIsNotGiven] = useState<any>(false)
    const [emailIsNotGiven, setEmailIsNotGiven] = useState<any>(false)
    const [passwordIsNotGiven, setpasswordIsNotGiven] = useState<any>(false)
    const [hospitalIsNotGiven, setHospitalIsNotGiven] = useState<any>(false)
    const [specialityIsNotGiven, setSpecialityIsNotGiven] = useState<any>(false)

    useEffect(() => {
        if (isSuccessSpecialistMutation) {
            const newSpecialist = {
                firstName: specialistName,
                lastName: specialistSurname,
                speciality: specialistSpeciality,
                email: specialistEmail,
                gender: gender,
                createDate: new Date(),
                // authority: "Full",
                // hospital:specialistHospital,
                // password:specialistPassword
            }
            setSpecialistList((prevList: any) => prevList ? [...prevList, newSpecialist] : [newSpecialist])
            showToast({ severity: 'success', summary: 'Success', detail: 'Specialist added successfully' });
            onaddSpecialistDialogHide()
            setBlockUI(false)
        }

    }, [isSuccessSpecialistMutation]);

    useEffect(() => {
        if (isErrorSpecialistMutation) {
            showToast({ severity: 'error', summary: 'error', detail: 'Specialist could not be added' });
            onaddSpecialistDialogHide()
            setBlockUI(false)
        }
    }, [isErrorSpecialistMutation]);


    const onaddSpecialistDialogHide = () => {
        addSpecialistDefault()
        setAddSpecialistDialogVisible(false)
    }

    const onSaveSpecialistDialog = () => {
        let escape = false
        if (!specialistName) {
            escape = true;
            setNameIsNotGiven(true)
        }
        if (!specialistSurname) {
            escape = true;
            setSurnameIsNotGiven(true)
        }
        if (!specialistEmail) {
            escape = true;
            setEmailIsNotGiven(true)
        }
        if (!specialistPassword) {
            escape = true;
            setpasswordIsNotGiven(true)
        }
        if (!specialistHospital) {
            escape = true;
            setHospitalIsNotGiven(true)
        }
        if (!specialistSpeciality) {
            escape = true;
            setSpecialityIsNotGiven(true)
        }

        if (escape) {
            return
        }

        const newSpecialist = {
            firstName: specialistName,
            lastName: specialistSurname,
            speciality: specialistSpeciality,
            email: specialistEmail,
            gender: gender
            // createDate: new Date(),
            // authority: "Full",
            // hospital:specialistHospital,
            // password:specialistPassword
        }

        setBlockUI(true)
        addSpecialistMutation(newSpecialist)
    }

    const addSpecialistDefault = () => {
        setSpecialistName(undefined);
        setspecialistSurname(undefined);
        setSpecialistEmail(undefined)
        setSpecialistPassword(undefined);
        setSpecialistHospital(undefined);
        setSpecialistSpeciality(undefined);
        setGender(genderOptions[0])

        setNameIsNotGiven(false);
        setSurnameIsNotGiven(false);
        setEmailIsNotGiven(false)
        setpasswordIsNotGiven(false)
        setHospitalIsNotGiven(false)
        setSpecialityIsNotGiven(false)
    }

    const footerContent = (
        <div className="flex justify-content-center  flex-wrap">
            <Button label="Save" rounded style={{ backgroundColor: "#111F52", width: "20%" }} onClick={onSaveSpecialistDialog} />
        </div>
    );

    return (
        <BlockUI
            blocked={blockUI}
            fullScreen
            style={{ backgroundColor: "transparent" }}
            template={
                <i className="pi pi-spin pi-spinner" style={{ fontSize: "3rem" }}></i>
            }
        >
            <Dialog footer={footerContent} header="New Patient" visible={addSpecialistDialogVisible} style={{ width: '40vw' }} onHide={onaddSpecialistDialogHide}>
                <div className="flex flex-column flex-wrap gap-4 justify-content-center m-6">
                    <div className="flex flex-column gap-2 align-items-center userManagmentButton" >
                        <SelectButton value={gender} onChange={(e) => setGender(e.value)} options={genderOptions} />
                    </div>

                    <div className="flex flex-column gap-2  userManagmentInput">
                        <label htmlFor="name"><i className="pi pi-asterisk p-overlay-badge" style={{ fontSize: '0.5rem', color: 'red' }}>
                        </i> Specialist Name</label>
                        <InputText id="name" value={specialistName}
                            className={nameIsNotGiven ? specialistName ? undefined : "p-invalid mr-2" : undefined}
                            onChange={e => setSpecialistName(e.target.value)} />
                        {!specialistName && nameIsNotGiven && <Message severity="error" text="Name is required" />}
                    </div>

                    <div className="flex flex-column gap-2 userManagmentInput" >
                        <label htmlFor="surname"><i className="pi pi-asterisk p-overlay-badge" style={{ fontSize: '0.5rem', color: 'red' }}>
                        </i> Specialist Surname</label>
                        <InputText id="surname" value={specialistSurname}
                            className={surnameIsNotGiven ? specialistSurname ? undefined : "p-invalid mr-2" : undefined}
                            onChange={e => setspecialistSurname(e.target.value)} />
                        {!specialistSurname && surnameIsNotGiven && <Message severity="error" text="Surname is required" />}
                    </div>

                    <div className="flex flex-column gap-2 userManagmentInput" >
                        <label htmlFor="surname"><i className="pi pi-asterisk p-overlay-badge" style={{ fontSize: '0.5rem', color: 'red' }}>
                        </i> Specialist Email</label>
                        <InputText id="surname" value={specialistEmail}
                            className={emailIsNotGiven ? specialistEmail ? undefined : "p-invalid mr-2" : undefined}
                            onChange={e => setSpecialistEmail(e.target.value)} />
                        {!specialistEmail && emailIsNotGiven && <Message severity="error" text="Email is required" />}
                    </div>

                    <div className="flex flex-column gap-2 userManagmentInput userManagmentPassword" >
                        <label htmlFor="surname"><i className="pi pi-asterisk p-overlay-badge" style={{ fontSize: '0.5rem', color: 'red' }}>
                        </i> Specialist Password</label>
                        <Password value={specialistPassword}
                            className={passwordIsNotGiven ? specialistPassword ? undefined : "p-invalid mr-2" : undefined}
                            onChange={e => setSpecialistPassword(e.target.value)} toggleMask />
                        {!specialistPassword && passwordIsNotGiven && <Message severity="error" text="Password is required" />}
                    </div>

                    <div className="flex flex-column gap-2 userManagmentInput" >
                        <label htmlFor="nationalIdentityNumber"><i className="pi pi-asterisk p-overlay-badge" style={{ fontSize: '0.5rem', color: 'red' }}>
                        </i> Specialist Hospital</label>
                        <InputText id="nationalIdentityNumber" value={specialistHospital}
                            className={hospitalIsNotGiven ? specialistHospital ? undefined : "p-invalid mr-2" : undefined}
                            onChange={e => setSpecialistHospital(e.target.value)} />
                        {!specialistHospital && hospitalIsNotGiven && <Message severity="error" text="Hospital name is required" />}
                    </div>

                    <div className="flex flex-column gap-2 userManagmentInput" >
                        <label htmlFor="nationalIdentityNumber"><i className="pi pi-asterisk p-overlay-badge" style={{ fontSize: '0.5rem', color: 'red' }}>
                        </i> Specialist Speciality</label>
                        <InputText id="nationalIdentityNumber" value={specialistSpeciality}
                            className={specialityIsNotGiven ? specialistSpeciality ? undefined : "p-invalid mr-2" : undefined}
                            onChange={e => setSpecialistSpeciality(e.target.value)} />
                        {!specialistSpeciality && specialityIsNotGiven && <Message severity="error" text="Speciality is required" />}
                    </div>
                </div>
            </Dialog>
        </BlockUI>
    )

}

export default AddSpecialistDailog