import { Card } from "primereact/card"
import { ProgressBar } from "primereact/progressbar"
import GenderBarChart from "../Charts/genderBarChart"

const ThridGridForCards = () => {
    return (
        <div className="grid">
            <div className="col">
                <Card title="Least Interest" className="h-full">
                    <div className="flex flex-column gap-4 least">
                        <div>
                            <span>Search a doctor</span>
                            <ProgressBar className="h-1rem" value={70} color="#E3506B"></ProgressBar>
                        </div>
                        <div>
                            <span>Calendar</span>
                            <ProgressBar className="h-1rem" value={50} color="#E3506B"></ProgressBar>
                        </div>
                        <div>
                            <span>Logout</span>
                            <ProgressBar className="h-1rem" value={30} color="#E3506B"></ProgressBar>
                        </div>
                    </div>
                </Card>
            </div>
            <div className="col">
                <Card title="Most Interest" className="h-full">
                    <div className="flex flex-column gap-4 most">
                        <div>
                            <span>Plans</span>
                            <ProgressBar className="h-1rem" value={70} color="#24D6A5"></ProgressBar>
                        </div>
                        <div>
                            <span>Help & Support</span>
                            <ProgressBar className="h-1rem" value={50} color="#24D6A5"></ProgressBar>
                        </div>
                        <div>
                            <span>Home</span>
                            <ProgressBar className="h-1rem" value={30} color="#24D6A5"></ProgressBar>
                        </div>
                    </div>
                </Card>
            </div>
            <div className="col">
                <Card className="h-full" title="Users">
                    <GenderBarChart />
                </Card>
            </div>
        </div>
    )
}

export default ThridGridForCards