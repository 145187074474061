import { Card } from "primereact/card"

const SecondGridForCards = () => {
    return (
        <Card className="h-full">
            <div className="flex flex-column justify-content-center gap-4">
                <span className="text-center">Visitor in last 30 Minutes</span>
                <span className=" text-4xl font-bold text-center">40</span>
                <span className="text-center" style={{ color: "#22C55E" }}>+2 Visitors Per minutes</span>
                <div className="flex justify-content-center">
                    <img  src="/Graph.png" width={200} />
                </div>


                <div className="flex justify-content-between">
                    <div className="flex flex-column gap-2 align-items-center">
                        <span className="text-lg font-bold">Countries</span>
                        <span>UAE</span>
                        <span>Qatar</span>
                        <span>Türkiye</span>
                        <span>Saudi Arabia</span>
                        <span>Irak</span>
                        <span>Pakistan</span>
                        <span>Singapore</span>
                        <span>Australia</span>
                        <span>Russia</span>
                    </div>
                    <div className="flex flex-column gap-2 align-items-center">
                        <span className="text-lg font-bold">Visitors</span>
                        <span>12</span>
                        <span>8</span>
                        <span>6</span>
                        <span>5</span>
                        <span>5</span>
                        <span>1</span>
                        <span>1</span>
                        <span>1</span>
                        <span>1</span>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default SecondGridForCards