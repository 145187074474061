import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const SuccessBarChart = ({ contentManagmentInput }: any) => {

    const height = contentManagmentInput ? " h-16rem" : " h-10rem"

    const data = {
        labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        datasets: [
            {
                label: contentManagmentInput ? 'General Success' : 'Patient Success',
                data: [60, 75, 90, 50, 95, 70, 80],
                backgroundColor: '#E3506B',
                borderRadius: 5,
                barThickness: 30,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: contentManagmentInput ? true : false,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: contentManagmentInput ? 'General Success' : 'Patient Success',
                font: {
                    size: 18,
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                beginAtZero: true,
                min: 0,
                max: 100,
                ticks: {
                    stepSize: 25,
                },
            },
        },
    };

    return (
        <div className={'flex justify-content-center w-full' + height}>
            <Bar data={data} options={options} />
        </div>

    )
};

export default SuccessBarChart;