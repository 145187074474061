import { BrowserRouter, Route, Routes } from "react-router-dom"
import Login from "./Auth/login"
import MainLayout from "./MainLayout/mainLayout"
import UserManagement from "./UserManagement/userManagement"
import ContentManagement from "./ContentManagement/contentManagement"
import ReportAnalytics from "./ReportAnalytics/reportAnalytics"
import FeedbackSupport from "./FeedbackSupport/feedbackSupport"
import Home from "./Home/home"
import AllClinics from "./ReportAnalytics/Clinic/allClicics"


export default () => {
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/mobitheraAdmin" element={<MainLayout />} >
                    <Route path="/mobitheraAdmin/home" element={<Home />} />
                    <Route path="/mobitheraAdmin/userManagement" element={<UserManagement />} />
                    <Route path="/mobitheraAdmin/contentManagement" element={<ContentManagement />} />
                    <Route path="/mobitheraAdmin/reportAnalytics" element={<ReportAnalytics />} />
                    <Route path="/mobitheraAdmin/reportAnalytics/allClinics" element={<AllClinics />} />
                    <Route path="/mobitheraAdmin/feedbackSupport" element={<FeedbackSupport />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}