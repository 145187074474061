import { api, Axios } from "./axiosApi";

export class MobitheraAdminService {
    async login(credentials: any): Promise<any> {
        const response = await api.post("/AdminAuth/Login", credentials);

        const { data } = response.data;

        localStorage.setItem("accessToken", data?.accessToken);
        localStorage.setItem("refreshToken", data?.refreshToken);

        Axios.setAuthToken(data?.accessToken, data?.refreshToken);

        return response.data
    }

    async tokenCheck(): Promise<any> {
        const { accessToken, refreshToken } = Axios.getAuthToken();

        Axios.setAuthToken(accessToken, refreshToken);

        const response = await api.get("/AdminAuth/TokenCheck");
        return response.data
    }

    async getAllPatients(showButton: any): Promise<any> {
        if (showButton) {
            const response = await api.post("/admin/Patient/GetAll");
            return response.data
        }
        return undefined

    }

    async getAllPatientsByHospital(hospitalId: any): Promise<any> {
        const response = await api.post("/admin/Patient/GetByHospital", hospitalId);
        return response.data
    }

    async addPatient(patient: any): Promise<any> {
        const response = await api.post("/admin/Patient/Add", patient);
        return response.data
    }

    async getAllSpecialists(showButton: any): Promise<any> {
        if (showButton) {
            const response = await api.post("/admin/Consultant/GetAll");
            return response.data
        }
        return undefined

    }

    async getAllSpecialistsByHospital(hospitalId: any): Promise<any> {
        const response = await api.post("/admin/Consultant/GetByHospital", hospitalId);
        return response.data
    }

    async addSpecialist(specialist: any): Promise<any> {
        const response = await api.post("/admin/Consultant/Add", specialist);
        return response.data
    }

    async getAllAdmins(): Promise<any> {
        const response = await api.post("/Admin/GetAll");
        return response.data
    }

    async getAllHospitals(): Promise<any> {
        const response = await api.post("/admin/Hospital/GetAll");
        return response.data
    }

    async logout(): Promise<any> {
        const response = await api.post("/AdminAuth/Logout");

        localStorage.clear();

        return response.data
    }
}
