import { useEffect, useState } from "react";
import { useToastContext } from "../../Lib/Context/toastContext";
import AddEditExercise from "./addEditExercise";


const ExerciseRefactor = ({ videoUploadRef, imageUploadRef, exercises, setExercises, selectedExercise, setSelectedExercise } : any) => {
    const { showToast } = useToastContext();
    
    const [exerciseData, setExerciseData] = useState<any>({
        id: 0,
        name: "",
        periods: [],
        days: [],
        sets: 0,
        repeat: 0,
        tolerance: 0,
        restTime: 0,
        image: "",
        video: "",
        description: "",
    })

    const handleSave = () => {
        setExercises(exercises.map((item : any) => item.id === exerciseData.id ? exerciseData : item));
        allReferencesClear();
        showToast({ severity: "success", summary: "Exercise updated", detail: "Exercise details have been updated successfully" });
    }

    const handleDelete = (exercise : any) => {
        setExercises(exercises.filter((item : any) => item.id !== exercise.id));
        setSelectedExercise(undefined);
    }

    const allReferencesClear = () => {
        imageUploadRef?.current?.clear();
        videoUploadRef?.current?.clear();
    }

    useEffect(() => {
        allReferencesClear();
        if(selectedExercise) {
            setExerciseData(selectedExercise);
        }
    }, [selectedExercise])

    return (
        <>
            {selectedExercise ? (
                <AddEditExercise 
                    type="Edit" 
                    exerciseData={exerciseData}
                    setExerciseData={setExerciseData}
                    handleSave={handleSave} 
                    imageUploadRef={imageUploadRef} 
                    handleDelete={handleDelete}
                    videoUploadRef={videoUploadRef}
                />
            ) : (
                <div className="flex align-items-center justify-content-center h-full">
                    <div className="flex flex-column align-items-center gap-3">
                        <i className="pi pi-exclamation-triangle text-6xl text-gray-400"></i>
                        <p className="text-gray-400">Select an exercise to view or edit details</p>
                    </div>
                </div>
            )}
        </>
    )
}

export default ExerciseRefactor;