import { Badge } from "primereact/badge";
import { Card } from "primereact/card";import DoctorsDonutChart from "../../Charts/doctorsDonutChart";

const SecondGridForCardsClinic = () => {
    return (
        <Card className="h-full" title="Top Doctors">
            <DoctorsDonutChart />

            <div className="flex flex-row justify-content-between mt-8">
                <div className="flex flex-column gap-2">
                    <div className="flex flex-row  gap-3">
                        <Badge className="mt-2" style={{ backgroundColor: "#4EB8B1" }}></Badge>
                        <span>Jane Doe</span>
                        <span style={{ color: "#00B69B" }}>
                            <i className="pi pi-angle-double-up"></i> 43.29%
                        </span>
                    </div>
                    <div className="flex flex-row gap-3">
                        <Badge className="mt-2" style={{ backgroundColor: "#4B5FA5" }}></Badge>
                        <span>Jane Doe</span>
                        <span style={{ color: "#00B69B" }}>
                            <i className="pi pi-angle-double-up"></i> 36.11%
                        </span>
                    </div>
                    <div className="flex flex-row gap-3">
                        <Badge className="mt-2" style={{ backgroundColor: "#81A9FE" }}></Badge>
                        <span>Jane Doe</span>
                        <span style={{ color: "#00B69B" }}>
                            <i className="pi pi-angle-double-up"></i> 40%
                        </span>
                    </div>
                    <div className="flex flex-row gap-3">
                        <Badge className="mt-2" style={{ backgroundColor: "#FF9075" }}></Badge>
                        <span>Jane Doe</span>
                        <span style={{ color: "#00B69B" }}>
                            <i className="pi pi-angle-double-up"></i> 25.53%
                        </span>
                    </div>
                    <div className="flex flex-row gap-3">
                        <Badge className="mt-2" style={{ backgroundColor: "#EAB308" }}></Badge>
                        <span>Others</span>
                        <span style={{ color: "#B60000" }}>
                            <i className="pi pi-angle-double-down"></i> 12.14%
                        </span>
                    </div>
                </div>

                <div className="flex flex-column gap-2">
                    <span>105</span>
                    <span>95</span>
                    <span>84</span>
                    <span>66</span>
                    <span>152</span>
                </div>

            </div>
        </Card>
    )
}

export default SecondGridForCardsClinic