import { Dialog } from "primereact/dialog"
import SpecialistList from "../../../UserManagement/Specialist/specialistList"
import { useEffect, useState } from "react";
import { LazyParams } from "../../../Services/lazyParams";
import { useGetSpecialistsByHospitalMutation } from "../../../Hooks/useGetDataQuery";

const SpecialistDialog = ({ specialistDialogVisible, setSpecialistDialogVisible, clinicks, selectedClinicIndex }: any) => {
  const {
    mutate: specialistsByHospitalMutation,
    data: specialistsByHospitalData,
    isSuccess: isSuccessSpecialistsByHospital,
  } = useGetSpecialistsByHospitalMutation();

  const [specialistList, setSpecialistList] = useState<any>();

  useEffect(() => {
    if (isSuccessSpecialistsByHospital) {
      setSpecialistList(specialistsByHospitalData.data)
    }
  }, [isSuccessSpecialistsByHospital]);


  useEffect(() => {
    if (specialistDialogVisible) {
      const id = {
        hospitalId: clinicks[selectedClinicIndex].id
      }
      specialistsByHospitalMutation(id)
    }
  }, [specialistDialogVisible]);

  const [lazyParams, setLazyParams] = useState<LazyParams>({
    first: 0,
    rows: 10,
    page: 1,
    sortField: "registerDate",
    sortOrder: 1,
    filters: {},
  });

  return (
    <Dialog header="Specialist List" visible={specialistDialogVisible} style={{ width: '80vw' }} onHide={() => setSpecialistDialogVisible(false)}>
      <div className="p-2 reportTable">
        <SpecialistList lazyParamsForSpecialists={lazyParams} specialistList={specialistList} setSpecialistList={setSpecialistList} showAddButton={false} />
      </div>

    </Dialog>
  )

}

export default SpecialistDialog
