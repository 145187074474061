import { Button } from "primereact/button";
import { DataView } from "primereact/dataview";
import { Image } from "primereact/image";
import { Skeleton } from "primereact/skeleton";

const ExercisesDataView = ({ exercises, selectedExercise, setSelectedExercise, setAddNewExerciseDialogVisible } : any) => {
    const mockList : any[] = Array(16).fill(null);

    const handleSelectExercise = (item: any) => {
        if (selectedExercise?.id === item?.id) {
          setSelectedExercise(undefined);
          return;
        }
        setSelectedExercise(item);
      };

    const openAddNewDialog = () => {
        setAddNewExerciseDialogVisible(true);
    }

    const gridItem = (item:any) => {
        return (
            <div className="col p-2" key={item?.id}>
                {item ? (
                    <div onClick={() => handleSelectExercise(item)} className={`flex grid-item flex-column align-items-center ${selectedExercise?.id === item?.id ? "selected" : ""}`}>
                        <Image src={item?.image} width="150" height="150" className="exercises-image" />
                        <span className="text-black-alpha-90 flex text-center justify-content-center align-items-center gap-2 mt-1">
                            {item?.name}
                            {selectedExercise?.id === item?.id && (<i className="pi pi-check-circle text-2xl text-green-500"></i>)}
                        </span>
                    </div>
                ) : (
                    <div className="flex flex-column align-items-center gap-3 p-4">
                        <Skeleton className="w-12 shadow-2 border-round h-7rem" />
                        <Skeleton className="w-8rem border-round h-2rem" />
                    </div>
                )}
            </div>
        )
    }

    return (
        <div className="flex flex-column align-items-end gap-3">
            <Button onClick={openAddNewDialog} className="border-round-xl" label="Add New" severity="danger" icon="pi pi-plus" style={{ backgroundColor: "#E3506B" }} />
            <div className="w-full" style={{ height: "34rem", overflowY: "scroll", overflowX: "hidden" }}>
                <DataView value={exercises?.length > 0 ? exercises : mockList} itemTemplate={gridItem} />
            </div>
        </div>
    )
}

export default ExercisesDataView;