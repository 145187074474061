import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const GeneralAdherenceLineChart = ({ contentManagmentInput }: any) => {
    const height = contentManagmentInput? " h-16rem": " h-10rem"

    const data = {
        labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        datasets: [
            {
                label: contentManagmentInput ? 'General Adherence' : 'Patient Adherence',
                data: [100, 80, 75, 40, 20, 60, 65],
                fill: false,
                borderColor: '#111F52',
                borderWidth: 1,
                tension: 0.1,
                pointBackgroundColor: 'white',
                pointBorderColor: '#77B2DD',
                pointBorderWidth: 3,
                pointRadius: 5,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: contentManagmentInput ? true : false,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: contentManagmentInput ? 'General Adherence' : 'Patient Adherence',
                font: {
                    size: 18,
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                min: 0,
                max: 100,
                ticks: {
                    stepSize: 25,
                },
            },
        },
    };

    return (
        <div className={'flex justify-content-center w-full' + height}>
            <Line data={data} options={options} />
        </div>
    )
};

export default GeneralAdherenceLineChart;