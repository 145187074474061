import { Dialog } from "primereact/dialog"
import PatientList from "../../../UserManagement/Patient/patientList"
import { useEffect, useState } from "react";
import { LazyParams } from "../../../Services/lazyParams";
import { useGetPatientsByHospitalMutation } from "../../../Hooks/useGetDataQuery";

const PatientDialog = ({ patientDialogVisible, setPatientDialogVisible,clinicks,selectedClinicIndex }: any) => {

    const {
        mutate: patientsByHospitalMutation,
        data: patientsByHospitalData,
        isSuccess: isSuccessPatientsByHospital,
      } = useGetPatientsByHospitalMutation();

    const [patientList, setPatientList] = useState<any>();

    useEffect(() => {
        if (isSuccessPatientsByHospital) {
            setPatientList(patientsByHospitalData.data)
        }
    }, [isSuccessPatientsByHospital]);


    useEffect(() => {
        if (patientDialogVisible) {
            const id = {
                hospitalId:clinicks[selectedClinicIndex].id
            }
            patientsByHospitalMutation(id)
        }
    }, [patientDialogVisible]);

    const [lazyParams, setLazyParams] = useState<LazyParams>({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "registerDate",
        sortOrder: 1,
        filters: {},
    });
    

    return (
        <Dialog header="Patient List" visible={patientDialogVisible} style={{ width: '80vw' }} onHide={() => setPatientDialogVisible(false)}>
            <div className="p-2 reportTable">
                <PatientList lazyParamsForPatients={lazyParams} patientList={patientList} setPatientList={setPatientList} showAddButton={false}/>
            </div>

        </Dialog>
    )
}

export default PatientDialog