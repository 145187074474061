export const DayList = [
    {
        name: "monday",
        value: "Monday",
    },
    {
        name: "tuesday",
        value: "Tuesday",
    },
    {
        name: "wednesday",
        value: "Wednesday",
    },
    {
        name: "thursday",
        value: "Thursday",
    },
    {
        name: "friday",
        value: "Friday",
    },
    {
        name: "saturday",
        value: "Saturday",
    },
    {
        name: "sunday",
        value: "Sunday",
    },
]